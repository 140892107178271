import {useEffect, useRef} from "react";
import apiClient from "../../components/apiClient";
import {Link} from "react-router-dom";

const ShopTop = ({setTitle}) => {
    const first = useRef(false);
    useEffect(() => {
        if (setTitle === undefined) return;
        if (first.current) return;
        first.current = true;
        (async () => {
            const res = await apiClient.shop.check();
            console.log(res)
        })()
        setTitle("admin")
    }, [setTitle, first])
    return (
        <div className={"max-w-6xl mx-auto bg-[#F7F7F7] p-4 pt-4"}>
            <div className={"py-8 font-bold text-3xl"}>注文管理システム</div>
            <div className={"grid grid-cols-2 md:grid-cols-3 max-w-2xl mx-auto"}>
                <Link to={"/admin/orders"}
                      className={"bg-white p-4 flex flex-col rounded drop-shadow-md items-center mx-5 my-5"}>
                    <div className={"py-2 font-bold"}>注文状況</div>
                    <img className={"w-1/2"} src={"/img/customer.svg"} alt={"注文状況"}/>
                </Link>
                <Link to={"/admin/stock"}
                      className={"bg-white p-4 flex flex-col rounded drop-shadow-md items-center mx-5 my-5"}>
                    <div className={"py-2 font-bold"}>商品管理</div>
                    <img className={"w-1/2"} src={"/img/workshop.svg"} alt={"在庫設定"}/>
                </Link>
                <Link to={"/admin/manage_items"}
                      className={"bg-white p-4 flex flex-col rounded drop-shadow-md items-center mx-5 my-5"}>
                    <div className={"py-2 font-bold"}>商品登録</div>
                    <img className={"w-1/2"} src={"/img/tag.svg"} alt={"注文状況"}/>
                </Link>
            </div>
        </div>
    )
}
export default ShopTop