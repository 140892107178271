import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Debug from "./pages/debug";
import ShopPage from "./pages/shop";
import ItemPage from "./pages/item";
import Login from "./pages/login";
import Cart from "./pages/cart";
import {Order} from "./pages/order";
import Top from "./pages/top";
import ShopAll from "./pages/shopAll";
import Footer from "./components/Footer";
import Mypage from "./pages/mypage/top";
import ShopTop from "./pages/shop/top";
import Orders from "./pages/shop/orders";
import Header from "./components/Header";
import {useState} from "react";
import {Stock} from "./pages/shop/stock";
import {Me} from "./pages/mypage/me";
import {Register} from "./pages/mypage/register";
import {Terms} from "./pages/terms_of_service";
import {ManageItems} from "./pages/shop/manage_items";
import {PointAll} from "./pages/shop/pointAll";
import {Point} from "./pages/shop/point";

function App() {
    const [title, setTitle] = useState("");
    return (
        <div className="App">
            <BrowserRouter>
                <Header title={title} visible={true}/>
                <div className={"mx-auto bg-[#F0F0F0]"}>
                    <Routes>
                        <Route exact path={"/"} element={<Top setTitle={setTitle}/>}/>
                        <Route path={"/shop"} element={<ShopAll setTitle={setTitle}/>}/>
                        <Route exact path='/debug' element={<Debug setTitle={setTitle}/>}/>
                        <Route path={"/login"} element={<Login setTitle={setTitle}/>}/>
                        <Route path={"/shop/:id"} element={<ShopPage setTitle={setTitle}/>}/>
                        <Route path={"/shop/:ShopID/:ItemID"} element={<ItemPage setTitle={setTitle}/>}/>
                        <Route path={"/cart"} element={<Cart setTitle={setTitle}/>}/>
                        <Route path={"/order"} element={<Order setTitle={setTitle}/>}/>
                        <Route exact path={"/mypage"} element={<Mypage setTitle={setTitle}/>}/>
                        <Route path={"/terms_of_contact"} element={<Terms setTitle={setTitle}/>}/>
                        <Route path={"/mypage/me"} element={<Me setTitle={setTitle}/>}/>
                        <Route path={"/mypage/register"} element={<Register setTitle={setTitle}/>}/>
                        <Route exact path={"/mypage/point"} element={<PointAll setTitle={setTitle}/>}/>
                        <Route path={"/mypage/point/:ShopID"} element={<Point setTitle={setTitle}/>}/>


                        <Route exact path={"/admin"} element={<ShopTop setTitle={setTitle}/>}/>
                        <Route path={"/admin/orders"} element={<Orders setTitle={setTitle}/>}/>
                        <Route path={"/admin/stock"} element={<Stock setTitle={setTitle}/>}/>
                        <Route path={"/admin/manage_items"} element={<ManageItems setTitle={setTitle}/>}/>
                    </Routes>
                </div>
                {/*<div className={"flex flex-col"}>*/}
                {/*    <Link to={"/debug"}>debug page</Link>*/}
                {/*    <Link to={"/shop"}>shop page</Link>*/}
                {/*    <Link to={"/shop/syokushin"}>shop/syokushin page</Link>*/}
                {/*    <Link to={"/shop/syokushin/syokushin-item1"}>shop/syokushin/syokushin-item1 page</Link>*/}
                {/*    <Link to={"/mypage"}>cart</Link>*/}
                {/*    <Link to={"/order"}>Order</Link>*/}
                {/*</div>*/}
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
