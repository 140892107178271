import {useEffect, useRef, useState} from "react";
import apiClient from "../../components/apiClient";
import {Link} from "react-router-dom";

export const PointAll = ({setTitle}) => {
    const first = useRef(false);
    const [shops, setShops] = useState([]);
    useEffect(() => {
        if (shops === undefined) return;
        if (first.current) return;
        first.current = true;
        (async () => {
            const res = await apiClient.pub.shopGet();
            const data = await res.json();
            console.log(data);
            setShops(data.shops);
        })()
        setTitle("ポイント")
    }, [first, setTitle, shops]);
    return (
        <div className={"max-w-6xl mx-auto"}>
            <div className={"pt-28 pb-16 bg-white"}>
                <div className={"my-8 text-xl font-bold"}>ポイントカード一覧</div>
                <div className={"grid grid-cols-1 md:grid-cols-2"}>
                    {shops.map(shop => {
                        return (
                            <Link to={"/mypage/point/" + shop.shopID} key={shop.shopID}>
                                <div className={"grid grid-cols-2 bg-white drop-shadow-lg m-4 p-4 border"}>
                                    <div className={"text-left p-4 flex flex-col justify-center"}>
                                        <div className={"text-xl tracking-widest font-bold"}>{shop.name}</div>
                                        <div className={"mt-2"}>{shop.detail}</div>
                                    </div>
                                    <div>
                                        <img src={shop.img} alt={shop.name} className={"p-2"}/>
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}