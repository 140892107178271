import React, {useEffect, useRef, useState} from "react";
import apiClient from "../../components/apiClient";
import {OrdersList} from "../order";
import Popup from "reactjs-popup";
import authClient from "../../components/auth";
import useSound from "use-sound";
import Sound from "../../sound/order.mp3"
import {Link} from "react-router-dom";

const ConfirmPopup = ({el, yes, label}) => {
    return (
        <Popup trigger={el} modal nested>
            {close =>
                (
                    <div className={"modal my-4"}>
                        <div className={"text-center font-bold py-4"}>{label}</div>
                        <div className={"flex items-center justify-center py-4"}>
                            <button className={"text-white bg-gray-600 px-3 py-1 rounded mr-4"} onClick={close}>いいえ
                            </button>
                            <button className={"text-white bg-red-600 px-3 py-1 rounded"} onClick={() => {
                                yes();
                                close();
                            }}>はい
                            </button>
                        </div>
                    </div>
                )
            }
        </Popup>
    )
}

const pad2 = (txt) => {
    return ("0" + txt).slice(-2)
}

const StartOrderPopup = ({menus, setReserving}) => {
    const endUnix = useRef(0);
    return (
        <Popup trigger={<button className={"px-2 py-1 bg-red-600 text-white drop-shadow-md rounded mb-1 mr-1"}
        >受付を開始する</button>} modal nested onOpen={() => {
            (async () => {
                await authClient.check3day()
            })()
        }}>
            {
                orderClose => {
                    return (
                        <div className={"modal my-4"}>

                            <div className={"text-center font-bold pt-4"}>{"受け付けるメニューを選んでください"}</div>
                            <div className={"grid grid-cols-2 items-center justify-center py-4"}>
                                {menus.map(menu => {
                                    return (
                                        <ConfirmPopup el={
                                            <button
                                                className={"text-white bg-orange-600 drop-shadow p-3 rounded m-2"}>
                                                {menu.name}
                                            </button>} label={
                                            <div>
                                                <div>本当に「<span className={"text-red-600"}>{menu.name}</span>」メニューで受付開始しますか？
                                                </div>
                                                <div className={"flex items-center justify-center mt-6"}>
                                                    <div>受付終了時刻</div>
                                                    <select className={"p-3 border border-gray-600 rounded mx-3"}
                                                            onChange={(e) => {
                                                                endUnix.current = Number(e.target.value);
                                                            }
                                                            }>
                                                        {
                                                            (() => {
                                                                const now = new Date();
                                                                let tmp = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, 0, 0);

                                                                console.log(tmp)
                                                                endUnix.current = tmp.getTime()
                                                                const repEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
                                                                let ds = [];
                                                                while (tmp < repEnd) {
                                                                    let def = false;
                                                                    if (tmp.getHours() === 15 && tmp.getMinutes() === 0) {
                                                                        endUnix.current = tmp.getTime();
                                                                        def = true;
                                                                    }
                                                                    ds.push([tmp, def])
                                                                    tmp = new Date(tmp.getTime() + 1000 * 60 * 5);
                                                                }
                                                                return ds.map(item => {
                                                                    const d = item[0];
                                                                    const def = item[1];
                                                                    const txt = `${pad2(d.getMonth() + 1)}/${pad2(d.getDate())} ${pad2(d.getHours())}:${pad2(d.getMinutes())}`
                                                                    return (
                                                                        <option value={d.getTime()}
                                                                                defaultChecked={def}>{txt}</option>
                                                                    )
                                                                })
                                                            })()
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        } yes={() => {
                                            (async () => {
                                                orderClose()
                                                const res = await apiClient.shop.updateActiveMenu(menu.menuID, endUnix.current);
                                                const data = await res.json();
                                                console.log(data)
                                                setReserving(data.shop.activeMenuID)
                                            })()
                                        }}/>
                                    )
                                })}
                            </div>
                        </div>
                    )
                }
            }
        </Popup>
    )
}

const sortOrders = (orders) => {
    const targetStatus = 0;
    orders.sort((i, j) => {
        if (i.Status !== j.Status) {
            if (i.Status !== targetStatus) return 1;
            if (j.Status !== targetStatus) return -1;
        }
        // if (i.Status !== j.Status) return 1;
        // if (i.Status !== j.Status) return -1;
        return j.reserveTime;
    })
    console.log(orders)
    return orders
}

const pad = (base) => {
    return base.toString().padStart(2, '0')
}

const Orders = ({setTitle}) => {
    const [orders, setOrders] = useState([]);
    const [menus, setMenus] = useState([]);
    const [reserving, setReserving] = useState("")
    const [lastUpdate, setLastUpdate] = useState("");
    const [play, {stop, pause}] = useSound(Sound);
    const ordered = useRef(false);
    const isFirst = useRef(true);
    setTitle("admin")
    useEffect(() => {
        if (!isFirst.current) return;
        isFirst.current = false;
        (async () => {
            await fetchData();
            const menus = await apiClient.shop.getMenus();
            const data = await menus.json();
            console.log("latest", data)
            setMenus(data.menus);
            setReserving(data.shop.activeMenuID)
            play();
        })();
        setInterval(() => {
            (async () => {
                await fetchData()
            })()
        }, 60000);
    }, [])

    const monitorOrdered = () => {
        if (ordered.current) {
            ordered.current = false;
            play();
        }
        setTimeout(monitorOrdered, 60000)
    }

    const fetchData = async () => {
        const beforeIds = new Set(JSON.parse(localStorage.getItem("wannabuy-ids")));
        const res = await apiClient.shop.getOrders();
        const data = await res.json();
        const orders = data.orders;
        setOrders(sortOrders(orders))
        console.log("orders", data)
        const d = new Date();
        const lastdate = d.getFullYear() + "/" + pad(d.getMonth() + 1) + "/" + pad(d.getDate()) + " " + pad(d.getHours()) + ":" + pad(d.getMinutes()) + ":" + pad(d.getSeconds());
        console.log("lastdate", lastdate)
        setLastUpdate(lastdate)
        let ids = [];
        // check new item
        for (let order of data.orders) {
            let i = order.orderedAt + order.orderID;
            if (!beforeIds.has(i)) {
                play();
                console.log("NEW ITEM")
                ordered.current = true;
            }
            ids.push(i)
        }
        console.log(ids)
        localStorage.setItem("wannabuy-ids", JSON.stringify(ids))
    }


    const updateOrders = (newOrder) => {
        setOrders((prev) => {
                const res = prev.map((order) => {
                    if (order.shop.shopID === newOrder.shop.shopID && order.orderedAt === newOrder.orderedAt) return newOrder
                    return order
                })
                return sortOrders(res);
            }
        )
    }

    const getMenuName = (menuID) => {
        for (let m of menus) {
            if (m.menuID === menuID) return m.name
        }
    }

    const normalizePhoneNumber = (num) => {
        if (num.startsWith("+81")) {
            num = num.substring(3)
        }
        if (!num.startsWith("0")) {
            num = "0" + num;
        }
        return num
    }

    return (
        <div className={"max-w-6xl mx-auto bg-[#F7F7F7] p-4"}>
            {/*<div*/}
            {/*    className={"bg-black w-full h-full z-50 top-0 left-0 opacity-90 text-white text-4xl fixed flex items-center justify-center"}*/}
            {/*    onClick={(e) => {*/}
            {/*        console.log("CLICK", e.target)*/}
            {/*        monitorOrdered();*/}
            {/*        e.target.remove()*/}
            {/*    }}>*/}
            {/*    <div>画面をクリックしてください</div>*/}
            {/*</div>*/}
            <div className={"text-left"}>
                <Link to={"/admin"} className={"inline-block p-1 border border-gray-400 border-2"}>⇐トップに戻る</Link>
            </div>
            <div className={"flex justify-center drop-shadow flex-col lg:flex-row"}>
                {reserving !== "NONE" ?
                    <div className={"text-2xl text-red-600 font-bold"}>「{getMenuName(reserving)}」で受付中</div> :
                    <div className={"text-2xl text-blue-600 font-bold"}>注文を受付していません</div>
                }
                {reserving === "NONE" ?
                    <StartOrderPopup menus={menus} setReserving={setReserving}/> :
                    <ConfirmPopup el={<button
                        className={"px-2 py-1 bg-blue-600 text-white drop-shadow-md rounded mb-1 mr-1"}
                    >受付を終了する</button>} label={"本当に受付終了にしますか？"} yes={() => {
                        (async () => {
                            const res = await apiClient.shop.updateActiveMenu("NONE", 1);
                            const data = await res.json();
                            console.log(data)
                            setReserving(data.shop.activeMenuID)
                        })()
                    }}/>
                }
            </div>
            <div className={"flex justify-center items-end"}>
                <div className={"font-bold text-2xl"} onClick={() => {
                    play()
                    console.log("PLAY")
                }}>注文一覧
                </div>
                <div className={"w-4"}></div>
                <div>最終更新日時：{lastUpdate}</div>
            </div>
            <OrdersList orders={orders} type={"shop"} AddComp={(order) => {
                const phoneNumber = normalizePhoneNumber(order.user.phone_number);
                return (
                    <div className={"text-left my-2"}>
                        <ConfirmPopup el={<button
                            className={"px-2 py-1 bg-purple-700 text-white drop-shadow-md rounded mb-1 text-sm mr-1"}
                        >商品受け取り待ちにする</button>} label={"本当に受け取り待ちしますか？"} yes={() => {
                            (async () => {
                                const res = await apiClient.shop.updateOrderStatus(order.shopID, order.orderedAt, 0);
                                const data = await res.json();
                                updateOrders(data);
                                console.log(data)
                            })()
                        }}/>
                        <ConfirmPopup el={<button
                            className={"px-2 py-1 bg-red-600 text-white drop-shadow-md rounded mb-1 mr-1 text-sm"}
                        >商品渡し済みにする</button>} label={"本当にお渡し済みにしますか？"} yes={() => {
                            (async () => {
                                const res = await apiClient.shop.updateOrderStatus(order.shopID, order.orderedAt, 2);
                                const data = await res.json();
                                updateOrders(data);
                                console.log(data)
                            })()
                        }}/>
                        <ConfirmPopup el={<button
                            className={"px-2 py-1 bg-blue-600 text-white drop-shadow-md rounded mb-1 text-sm"}
                        >注文をキャンセルする</button>} label={"本当にキャンセルしますか？"} yes={() => {
                            (async () => {
                                const res = await apiClient.shop.updateOrderStatus(order.shopID, order.orderedAt, 1);
                                const data = await res.json();
                                updateOrders(data);
                                console.log(data)
                            })()
                        }}/>
                        <div className={"font-bold"}>注文者情報</div>
                        <div className={"flex"}>
                            <div>電話番号</div>
                            <a className={"block ml-2"} href={"tel:" + phoneNumber}>{phoneNumber}</a>
                        </div>
                    </div>
                )
            }
            }/>
        </div>
    )
}
export default Orders