import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import apiClient from "../components/apiClient";

const ItemPage = ({setTitle}) => {
    const first = useRef(false);
    const [item, setItem] = useState({});
    const [shop, setShop] = useState({});
    const nav = useNavigate();
    const [price, setPrice] = useState(0);
    const options = useRef({});
    const [num, setNum] = useState(1);
    const params = useParams();
    useEffect(() => {
        if (params.ShopID === undefined) return;
        if (first.current) return;
        first.current = true;
        (async () => {
            const res = await apiClient.pub.item(params.ShopID, params.ItemID);
            const data = await res.json();
            setItem(data.item)
            setShop(data.shop)
            setTitle(data.shop.name)
            console.log(data)
            setPrice(data.item.price);
        })();
    }, [params, setTitle]);

    useEffect(() => {
        if (Object.keys(item).length === 0) return;
        console.log(item)
        for (let o of item.options) {
            options.current[o.optionID] = o.defaultChoice;
        }
    }, [item])

    const changeOption = (e) => {
        console.log(options.current, e)
        const optionID = e.target.name;
        options.current[optionID] = e.target.value;
        console.log(options.current)
        let priceSum = item.price;
        for (let opt of item.options) {
            for (let cho of opt.choices) {
                if (options.current[opt.optionID] === cho.choiceID) {
                    priceSum += cho.price;
                    break;
                }
            }
        }
        setPrice(priceSum);
        console.log(options, options.current)
    }

    const order = () => {
        (async () => {
            console.log("order", options.current)
            const res = await apiClient.auth.cartPut(shop.shopID, item.itemID, options.current, num)
            console.log(await res.json())
            nav("/shop/" + shop.shopID)
        })()

    }

    const addNum = (v) => {
        setNum((prevCount) => Math.max(prevCount + v, 1));
    }

    return (
        <div>
            <div className={"pt-28 pb-4 max-w-6xl mx-auto"}>
                {!!Object.keys(item).length && (
                    <div className={"bg-white rounded-xl p-4 drop-shadow-xl mx-3"}>
                        <div className={"font-bold text-lg"}>{item.name}</div>
                        <div>￥{item.price.toLocaleString()}</div>
                        <div className={"py-1"}>{item.detail}</div>
                        <img className={"rounded-xl mx-auto max-w-sm w-full"} src={item.img} alt={item.name}/>
                        <div className={"font-bold text-left mt-4 max-w-3xl mx-auto"}>オプション</div>
                        <div className={"px-4 max-w-3xl mx-auto"}>
                            {item.options.map(o => {
                                return (
                                    <div key={o.optionID} className={"py-1"}>
                                        <div className={"text-left font-bold py-2"}>{o.name}</div>
                                        <select className={"w-full border p-2"} onChange={changeOption}
                                                defaultValue={o.defaultChoice} name={o.optionID}>
                                            {o.choices.map(c => {
                                                return (
                                                    // <div key={o.choiceID}>
                                                    <option value={c.choiceID} id={c.choiceID}>
                                                        {c.name} (¥{c.price.toLocaleString()})
                                                        {(c.eatIn && c.takeOut) === false &&
                                                            <div><span>&nbsp;&nbsp;&nbsp;(</span>
                                                                {c.eatIn === false &&
                                                                    <span>店内注文 </span>
                                                                }
                                                                {c.takeOut === false &&
                                                                    <span>持ち帰り注文 </span>
                                                                }<span>不可)</span>
                                                            </div>
                                                        }
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={"py-3"}>
                            <div>注文数</div>
                            <div className={"flex justify-center"}>
                                <button className={""} onClick={() => addNum(-1)}>
                                    <img src={"/img/minus.svg"} alt={"minus"} className={"w-6"}/>
                                </button>
                                <div className={"px-4"}>{num}</div>
                                <button className={""} onClick={() => addNum(1)}>
                                    <img src={"/img/plus.svg"} alt={"plus"} className={"w-6"}/>
                                </button>
                            </div>
                        </div>

                        {item.soldOut ?
                            <div className={"rounded-xl py-3 px-6 bg-gray-500 text-white"}>
                                売り切れ
                            </div> :
                            <button className={"rounded-xl py-3 px-6 bg-red-500 text-white"}
                                    onClick={order}>
                                <div>料金 ￥{(price * num).toLocaleString()}</div>
                                カートに追加
                            </button>
                        }
                    </div>
                )}
            </div>
        </div>
    )
}

export default ItemPage