import Cookies from 'js-cookie';
import {Amplify, Auth as Cognito} from "aws-amplify"

const AuthConfig = {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
    cookieStorage: {
        domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
        path: "/",
        expires: 30,
        sameSite: "lax",
        secure: true,
    }
}

Amplify.configure({Auth: AuthConfig});

function createURLSearchParams(data) {
    const params = new URLSearchParams();
    Object.keys(data).forEach(key => params.append(key, data[key]));
    return params;
}

class Cookie {

    write(key, value) {
        // const query = `${key}=${JSON.stringify(value)}; Max-Age=${60 * 60 * 24 * 21}; Secure;`
        // console.log(query)
        // document.cookie = query;
        Cookies.set(key, JSON.stringify(value), {secure: true, expires: 60 * 60 * 24 * 21});
    }

    read(key) {
        let res;
        try {
            res = JSON.parse(Cookies.get(key))
        } catch (e) {
            res = "";
        }
        return res;
    }

    delete(key) {
        Cookies.remove(key)
    }

}

class Auth {
    constructor() {
        this.tokenEndpoint = "https://auth.wannabuy.click/oauth2/token"
        this.cookie = new Cookie();
        console.log(this.tokenEndpoint)
        Cognito.currentAuthenticatedUser().then((res) => {
            console.log(res)
        }).catch((res) => {
            console.log(res)
        });
    }

    async getTokenFromCode(code) {
        const res = await fetch(this.tokenEndpoint, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            body: createURLSearchParams({
                "grant_type": "authorization_code",
                "client_id": process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
                "redirect_uri": process.env.REACT_APP_COGNITO_REDIRECT + "/login",
                "code": code,
                "scope": "openid",
            }),
        });
        if (res.status === 200) {
            const data = await res.json();
            console.log(data)
            this.cookie.write("wannabuy-token", data.id_token);
            this.cookie.write("wannabuy-accesstoken", data.access_token);
            this.cookie.write("wannabuy-refreshtoken", data.refresh_token);
            this.cookie.write("wannabuy-login", new Date().getTime())
            await new Promise(s => setTimeout(s, 100))
            const token = this.cookie.read("wannabuy-token")
            console.log("Auth token is A", token)
            return data;
        } else {
            console.log("Failed to get code from token", await res.text())
        }
    }

    async check3day() {
        const lastLogin = this.cookie.read("wannabuy-login")
        const date = new Date(lastLogin);
        const now = new Date();
        let delta = now - date;
        if (isNaN(delta)) delta = 2 ** 53;
        console.log("check3day", delta, delta > 3 * 24 * 60 * 60 * 1000)
        if (delta > 3 * 24 * 60 * 60 * 1000) {
            this.logout(true)
            return false
        } else {
            return true
        }
    }

    logout(login = false) {
        // this.cookie.delete("wannabuy-token")
        // this.cookie.delete("wannabuy-accesstoken")
        // this.cookie.delete("wannabuy-refreshtoken")
        (async () => {
            await Cognito.signOut()
            window.location.href = "/mypage/register"
        })()
    }

    async refreshToken() {
        // date check
        console.log(this.tokenEndpoint)
        const res = await fetch(this.tokenEndpoint, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            body: createURLSearchParams({
                "grant_type": "refresh_token",
                "client_id": process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
                "redirect_uri": process.env.REACT_APP_COGNITO_REDIRECT + "/login",
                "refresh_token": this.cookie.read("wannabuy-refreshtoken"),
                "scope": "openid",
            }),
        });
        if (res.status === 200) {
            const data = await res.json();
            console.log("data", data);
            this.cookie.write("wannabuy-token", data.id_token);
            this.cookie.write("wannabuy-accesstoken", data.access_token);
            return true;
        } else {
            console.log("Failed to refresh token", await res.text())
        }
        await new Promise(s => setTimeout(s, 100))
        return false;
    }

    async getToken() {
        try {
            const res = await Cognito.currentSession();
            const token = res.getIdToken();
            return token.getJwtToken()
        } catch (err) {
            return ""
        }

        // const token = this.cookie.read("wannabuy-token")
        // // console.log("Auth token is B", token)
        // return token
    }

    setLastLogin() {
        this.cookie.write("wannabuy-login", new Date().getTime())
    }


}

const authClient = new Auth();
export default authClient;