import {Link, useNavigate} from "react-router-dom";
import authClient from "../../components/auth";
import {LoaderCircle} from "../../components/loader";
import {useEffect, useRef, useState} from "react";
import jwtDecode from "jwt-decode";


const Top = ({setTitle}) => {
    const nav = useNavigate();
    const [loader, setLoader] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [fillForm, setFillForm] = useState("");
    const first = useRef(false);
    useEffect(() => {
        if (first.current) return;
        first.current = true;
        (async () => {
            setTitle("マイページ")
            const token = await authClient.getToken();
            if (token !== "") {
                setAuthorized(true);
                const data = jwtDecode(token);
                const query = `entry.298948907=${data.sub}&entry.1283213221=${data["family_name"]} ${data["given_name"]}&entry.1393788363=${data["email"]}&entry.925287693=${data["phone_number"]}`;
                console.log(query)
                setFillForm(encodeURI(query))
            }
        })();
    }, [setTitle])
    return (
        <div>
            <LoaderCircle loader={loader} label={"ログアウト中..."}/>
            <div className={"pt-24 px-4 max-w-6xl mx-auto pb-4"}>
                <div className={"my-4 grid grid-cols-1 lg:grid-cols-2"}>
                    <div className={"px-3 py-1 md:p-3"}>
                        <Link to={"/mypage/me"}
                              className={"flex items-center justify-center bg-white p-5 rounded-lg drop-shadow-lg w-full"}>
                            <img src={"/img/circle.png"} alt={"カート"} className={"w-8 mr-3"}/>
                            <div>会員情報</div>
                        </Link>
                    </div>
                    <div className={"px-3 py-1 md:p-3"}>
                        <Link to={"/cart"}
                              className={"flex items-center justify-center bg-white p-5 rounded-lg drop-shadow-lg w-full"}>
                            <img src={"/img/shopping-cart.svg"} alt={"カート"} className={"w-8 mr-3"}/>
                            <div>カート</div>
                        </Link>
                    </div>
                    <div className={"px-3 py-1 md:p-3"}>
                        <Link to={"/order"}
                              className={"flex items-center justify-center bg-white p-5 rounded-lg drop-shadow-lg w-full"}>
                            <img src={"/img/store.svg"} alt={"注文履歴"} className={"w-8 mr-3"}/>
                            <div>注文履歴</div>
                        </Link>
                    </div>
                    <div className={"px-3 py-1 md:p-3"}>
                        <Link to={"/mypage/point"}
                              className={"flex items-center justify-center bg-white p-5 rounded-lg drop-shadow-lg w-full"}>
                            <img src={"/img/badge.svg"} alt={"注文履歴"} className={"w-8 mr-3"}/>
                            <div>ポイント</div>
                        </Link>
                    </div>
                    <div className={"px-3 py-1 md:p-3"}>
                        <a href={"https://docs.google.com/forms/d/e/1FAIpQLSe4iKfOEHWHQRvEbDmAMYP5gZ5L81BnKZJxMr3yneLbmaBppg/viewform?" + fillForm}
                           target={"_blank"}
                           rel="noopener noreferrer"
                           className={"flex items-center justify-center bg-white p-5 rounded-lg drop-shadow-lg w-full"}>
                            <img src={"/img/form.svg"} alt={"お問い合わせ"} className={"w-8 mr-3"}/>
                            <div>お問い合わせ</div>
                        </a>
                    </div>
                    <div className={"px-3 py-1 md:p-3"}>
                        <Link to={"/terms_of_contact"}
                              className={"flex items-center justify-center bg-white p-5 rounded-lg drop-shadow-lg w-full"}>
                            <img src={"/img/check.svg"} alt={"お問い合わせ"} className={"w-8 mr-3"}/>
                            <div>利用規約</div>
                        </Link>
                    </div>
                    <div className={"px-3 py-1 md:p-3"}>
                        {authorized ?
                            <button onClick={() => {
                                (async () => {
                                    setLoader(true)
                                    authClient.logout();
                                    await new Promise(s => setTimeout(s, 1000))
                                    nav("/")
                                })()
                            }}
                                    className={"flex items-center justify-center bg-white p-5 rounded-lg drop-shadow-lg w-full"}>
                                <img src={"/img/sign.png"} alt={"ログアウト"} className={"w-8 mr-3"}/>
                                <div>ログアウト</div>
                            </button> :
                            <Link to={"/mypage/register"}
                                  className={"flex items-center justify-center bg-white p-5 rounded-lg drop-shadow-lg w-full"}>
                                <img src={"/img/sign.png"} alt={"ログイン"} className={"w-8 mr-3"}/>
                                <div>ログイン</div>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Top