import {useEffect, useRef} from "react";
import authClient from "../components/auth";
import {useNavigate, useSearchParams} from "react-router-dom";

const Login = () => {
    const [searchParams] = useSearchParams();
    const requested = useRef(false);
    const navigate = useNavigate();
    const auth = authClient;
    useEffect(() => {
        console.log(searchParams, searchParams.get("code"))
        if (requested.current) return;
        requested.current = true;
        if (searchParams.get("code") === null) {
            (async () => {
                // const res = await auth.getToken();
                // console.log(res)
            })();
        } else {
            (async () => {
                await auth.getTokenFromCode(searchParams.get("code"));
                const reflog = localStorage.getItem("reflog");
                if (reflog !== undefined) {
                    window.location.href = reflog;
                }
            })();
        }
    }, [searchParams]);

    return (
        <div className={"m-8 text-left"}>
            <button onClick={() => {
                (async () => {
                    await auth.refreshToken()
                })();
            }}>refresh
            </button>
        </div>
    )
}

export default Login