import {useEffect, useRef, useState} from "react";
import auth from "../../components/auth";
import jwtDecode from "jwt-decode";

export const Me = ({setTitle}) => {
    const first = useRef(false);
    const [member, setMember] = useState({});
    const [authorized, setAuthorized] = useState(false);
    useEffect(() => {
        if (typeof setTitle !== "function") return;
        if (first.current !== false) return
        setTitle("会員情報")
        first.current = true;
        (async () => {
            const token = await auth.getToken();
            if (token === "") return;
            setAuthorized(true)
            const data = jwtDecode(token);
            setMember(data)
            console.log(data);
        })()
    }, [setTitle])

    return (
        <div className={"pt-28 pb-4 max-w-6xl mx-auto"}>
            {authorized ?
                <div className={"pt-10 pb-8 m-3 bg-white drop-shadow-lg rounded-lg"}>
                    <div className={"font-bold text-xl"}>あなたの会員情報</div>
                    {!first.current ? null : <div>
                        <div className={"flex items-center justify-center flex-col my-4"}>
                            <div className={"font-bold"}>会員番号</div>
                            <div>{member.sub}</div>
                        </div>
                        <div className={"flex items-center justify-center flex-col my-4"}>
                            <div className={"font-bold"}>メールアドレス</div>
                            <div>{member.email}</div>
                        </div>
                        <div className={"flex items-center justify-center flex-col my-4"}>
                            <div className={"font-bold"}>電話番号</div>
                            <div className={"flex items-center"}>{member.phone_number}
                                <div className={"text-sm px-3"}>
                                    {member.phone_number_verified ?
                                        <div className={"bg-red-600 text-white p-1 rounded"}>認証済み</div> :
                                        <div className={"bg-blue-600 text-white p-1 rounded"}>認証待ち</div>}
                                </div>
                            </div>
                        </div>
                    </div>}
                </div> :
                <div className={"text-2xl bg-white m-4 p-4 rounded-lg drop-shadow-lg"}>ログインしてください</div>}
        </div>
    )
}