import {Link} from "react-router-dom";

export const Header = ({title}) => {
    if (title === "admin") return null;
    return (
        <div className={"fixed top-0 z-50 w-full"}>
            <div className={"bg-black h-4"}></div>
            <div className={"bg-[#FFED00]"}>
                <div className={"flex items-center px-3 py-2 max-w-6xl mx-auto"}>
                    <Link to={"/"} className={"basis-1/4"}>
                        <img src={"/img/logo.png"} alt={"wanna buy"} className={"max-h-16 w-auto"}/>
                    </Link>
                    <div className={"basis-1/2 font-bold text-xl"}>{title}</div>
                    <div className={"basis-1/4 font-bold flex justify-end text-sm"}>
                        {/*<div className={"m-2"}>新規キャンペーン中！</div>*/}
                        <Link to={"/mypage"}>
                            <div className={"mx-2 flex flex-col justify-center"}>
                                <img src={"/img/square.svg"} alt={"MyPage"} className={"max-h-9"}/>
                                <div>MyPage</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header