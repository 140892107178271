import React, {useEffect, useRef, useState} from "react";
import apiClient from "../components/apiClient";
import {Link} from "react-router-dom";
import {LoaderCircle} from "../components/loader";

export const WaitTime = ({menuID, unix}) => {
    if (menuID === "NONE") {
        return (
            <div className={"mb-2 font-bold text-red-600"}>
                現在、受け付けていません
            </div>
        )
    }
    const date = new Date(unix);
    const dateString = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`
    return (
        <div className={"mb-2 font-bold"}>最速受け取り {dateString}</div>
    )
}

const ShopAll = ({setTitle}) => {
    const first = useRef(false);
    const [shop, setShop] = useState({"shops": []});
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        if (first.current) return;
        first.current = true;
        (async () => {
            setTitle("お店一覧")
            const res = await apiClient.pub.shopGet();
            const data = await res.json();
            console.log(data);
            setShop(data);
            setLoader(false);
        })();
    }, [setTitle]);
    return (
        <div>
            <LoaderCircle loader={loader} label={"取得中..."}/>
            <div className={"p-7 pt-28 text-left max-w-6xl mx-auto bg-[#F7F7F7]"}>
                <div className={"font-bold text-xl flex items-center mb-4 mt-1"}>
                    <img src={"/img/store.svg"} alt={"お店"} className={"w-8 mr-2"}/>
                    <div>今すぐ注文できるお店</div>
                </div>

                <div className={"grid grid-cols-1 lg:grid-cols-3"}>
                    {shop.shops.map(s => {
                        return (
                            <Link to={"/shop/" + s.shopID} key={s.shopID} className={"block w-full p-2"}>
                                <div
                                    className={"border p-4 rounded-xl my-3 border-gray-400 drop-shadow-lg bg-white text-center"}>
                                    <div className={"text-xl font-bold mb-2"}>{s.name}</div>
                                    <div className={"mb-2"}>{s.detail}</div>
                                    <img src={s.img} alt={s.name} className={"mb-4 mx-auto"}/>
                                    {console.log(s)}
                                    <WaitTime unix={s.availableDuration.start.timeID} menuID={s.activeMenuID}/>
                                    <div className={"flex justify-center"}>
                                        {s.tags.map(t => {
                                            return (
                                                <div key={t}
                                                     className={"mr-3 rounded-xl px-2 text-gray-800"}>#{t}</div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ShopAll