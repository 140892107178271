import auth from "./auth";
import axios from "axios";

const goLogin = () => {
    window.location.href = "/mypage/register"

    // save current url to localstorage
    // localStorage.setItem("reflog", window.location.href);
    // const redirectURL = encodeURIComponent(process.env.REACT_APP_COGNITO_REDIRECT + "/login");
    // const cognitoURL = "https://vv6b5uu7vn2x-dev.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?client_id=5odassoe9mg348b4q2rvm1j7t&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + redirectURL;
    // console.log(cognitoURL)
    // window.location.href = cognitoURL;
}

class ApiClient {
    constructor(prefix = "") {
        this.prefix = prefix;
        this.baseUrl = process.env.REACT_APP_API_ENDPOINT + prefix;
    }

    async request(method, endpoint, data, login = false) {
        let url, reqData;
        if (method === "GET") {
            const query = new URLSearchParams(data);
            url = this.baseUrl + endpoint + "?" + query;
            reqData = {
                method: method,
                cache: "no-store",
            }
        } else {
            url = this.baseUrl + endpoint;
            reqData = {
                method: method,
                body: JSON.stringify(data),
                cache: "no-store",
            }
        }
        if (this.prefix !== "/pub") {
            const token = await auth.getToken()
            reqData.headers = {Authorization: token};
            console.log(login, "login")
            if (token === "" && login) {
                goLogin();
            }
        }
        console.log(this.prefix)
        const res = await fetch(url, reqData);
        const stateCate = Math.round(res.status / 100);
        console.log("login", login, stateCate)
        if (stateCate === 2) {
            return res
        }
        // else if (stateCate === 4 && login) {
        //     goLogin()
        // }
        return res
    }

    async get(endpoint, params, login = true) {
        console.log("GET", login)
        return await this.request("GET", endpoint, params, login)
    }

    async put(endpoint, data = {}) {
        return await this.request("PUT", endpoint, data, true)
    }

    async post(endpoint, data = {}) {
        return await this.request("POST", endpoint, data, true)
    }

    async delete(endpoint, data = {}) {
        return await this.request("DELETE", endpoint, data, true)
    }

}

class Pub extends ApiClient {
    constructor() {
        super("/pub");
    }

    async health() {
        return await this.get("/health")
    }

    async shopGet() {
        return await this.get("/shop")
    }

    async menu(ShopID) {
        const params = {
            "shopID": ShopID,
        };
        return await this.get("/menu", params);
    }

    async item(shopID, itemID) {
        const params = {
            "shopID": shopID,
            "itemID": itemID,
        };
        return await this.get("/item", params);
    }
}

class Auth extends ApiClient {
    constructor() {
        super("/auth");
    }

    async cartPut(shopID, itemID, options, num) {
        const data = {
            "shopID": shopID,
            "itemID": itemID,
            "options": options,
            "num": num,
        }
        return await this.put("/cart", data)
    }

    async cartGet(login = false) {
        console.log("cartGET", login)
        return await this.get("/cart", {}, login)
    }

    async cartDelete(shopID, itemID, options) {
        const data = {
            "shopID": shopID,
            "itemID": itemID,
            "options": options,
            "num": -1,
        }
        return await this.delete("/cart", data)
    }

    async orderPost(reserveTime) {
        const data = {
            "reserveTime": reserveTime,
            "seat": "TakeOut",
            "type": "TakeOut",
        }
        console.log("orderPost", data)
        return await this.post("/order", data)
    }

    async orderPostEatIn(reserveTime, seat) {
        const data = {
            "reserveTime": new Date().getTime(),
            "seat": seat,
            "type": "EatIn",
        }
        console.log("orderPostEatIn", data)
        return await this.post("/order", data)
    }

    async orderGet(login) {
        return await this.get("/order", {}, login)
    }

    async pointGet(shopID) {
        const data = {
            "shopID": shopID,
        }
        return await this.get("/point", data, false)
    }

    async check() {
        // return await this.get("/check")
        return await this.get("/check", {}, true)
    }

    async useToken(shopID, token) {
        return await this.get("/token", {
            "shopID": shopID,
            "token": token,
        }, true)
    }
}

class Shop extends ApiClient {
    constructor() {
        super("/shop");
    }

    async check() {
        return await this.get("/check", true)
    }

    async getOrders() {
        console.log("GET ORDERS")
        return await this.get("/orders", true)
    }

    async updateOrderStatus(id, at, status) {
        const data = {
            "shopID": id,
            "orderedAt": at,
            "status": status,
        }
        return await this.post("/order/status", data)
    }

    async getMenus() {
        return await this.get("/menus", true)
    }

    async updateActiveMenu(menuID, endUnix) {
        return await this.post("/reserve", {
            "menuID": menuID,
            "endUnix": endUnix,
        })
    }

    async getAllItems() {
        return await this.get("/allItems", true)
    }

    async setItemSoldOut(menuID, itemID, status) {
        console.log("req", menuID, itemID, status)
        return await this.post("/setItemSoldOut", {
            "menuID": menuID,
            "itemID": itemID,
            "status": status,
        })
    }

    async fileUpload(acceptedFiles) {
        const submitData = new FormData()
        const fd = new FormData();
        fd.append("file", acceptedFiles[0])
        submitData.append("file", acceptedFiles[0])

        return await axios.post(this.baseUrl + "/file/upload", submitData,
            {
                headers: {
                    "Authorization": await auth.getToken(),
                    'content-type': 'multipart/form-data',
                },
            })
        // return await fetch(this.baseUrl + "/file/upload", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "multipart/form-data",
        //         Authorization: await auth.getToken(),
        //     },
        //     body: submitData
        // })
    }

    async fileUpload2(acceptedFiles) {
        const submitData = new FormData()
        const fd = new FormData();
        fd.append("file", acceptedFiles[0])
        submitData.append("file", acceptedFiles[0])

        return await axios.post("https://bobvzi5uckw65zo52qu3xvwpiy0kmgrg.lambda-url.ap-northeast-1.on.aws/", submitData,
            {
                headers: {
                    "Authorization": await auth.getToken(),
                    'content-type': 'multipart/form-data',
                },
            })

    }

    async registerItem(data) {
        return await this.post("/item", data)
    }

    async addItemToMenu(menuID, itemID) {
        const data = {
            menuID: menuID,
            itemID: itemID,
        }
        return await this.post("/menu/item", data);
    }

    async deleteItemFromMenu(menuID, itemID) {
        const data = {
            menuID: menuID,
            itemID: itemID,
        }
        return await this.delete("/menu/item", data);
    }

}

class Client {
    constructor() {
        this.pub = new Pub();
        this.auth = new Auth();
        this.shop = new Shop();
    }
}

const api = new Client();

// const apiClient = new PubApi()
export default api;