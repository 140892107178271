import {Link} from "react-router-dom";

const Top = ({setTitle}) => {
    setTitle("")
    return (
        <div>
            <div style={{backgroundImage: "URL('/img/top.jpg')"}} className={"h-screen bg-cover relative h-[40rem]"}>
                <div className={"bg-black opacity-75 absolute w-full h-full z-0"}></div>
                <div className={"absolute text-white z-30 font-bold left-0 right-0 top-56 space-y-5"}>
                    <div className={"text-3xl lg:text-5xl"}>持ち帰り予約</div>
                    <div className={"text-5xl lg:text-7xl pb-20"}>WannaBuy</div>
                    <Link to={"/shop"}
                          className={"border px-4 py-3 border-red-900 bg-red-600 text-xl rounded-xl shadow-black"}>今すぐ注文！
                    </Link>
                </div>
            </div>
            <div className={"text-left max-w-6xl mx-auto bg-white"}>
                <div className={"text-center py-4 flex justify-center items-center"}>
                    <div className={"border-b-2 border-yellow-600 flex justify-center items-center p-2"}>
                        <img src={"/img/checkout.svg"} alt={"注文"}/>
                        <div className={"font-bold text-lg"}>WannaBuyの注文方法</div>
                    </div>
                </div>
            </div>
            <div className={"bg-white flex py-4 flex-wrap max-w-6xl mx-auto items-stretch "}>
                <div className={"drop-shadow-lg py-6 bg-[#F6F6F7] mx-8 rounded-xl mt-6 w-full lg:basis-[27%]"}>
                    <div className={"font-bold text-xl py-2"}>Step1</div>
                    <div><span className={"font-bold"}>お店</span>と<span className={"font-bold"}>商品</span>を選ぶ！</div>
                    <img src={"/img/step1.png"} alt={"step1"} className={"w-full mt-6 mx-auto"}/>
                </div>
                <div className={"drop-shadow-lg py-6 bg-[#F6F6F7] mx-8 rounded-xl mt-6 w-full lg:basis-[27%]"}>
                    <div className={"font-bold text-xl py-2"}>Step2</div>
                    <div>注文して、<span className={"font-bold"}>「注文番号」</span>を入手する！</div>
                    <img src={"/img/step2.png"} alt={"step2"} className={"w-2/3 mt-12 mx-auto"}/>
                </div>
                <div className={"drop-shadow-lg py-6 bg-[#F6F6F7] mx-8 rounded-xl mt-6 w-full lg:basis-[27%]"}>
                    <div className={"font-bold text-xl py-2"}>Step3</div>
                    <div>受け取り画面を見せ</div>
                    <div>レジで支払い、商品を受け取る！</div>
                    <img src={"/img/step3.png"} alt={"step3"} className={"w-full mt-3 mx-auto"}/>
                </div>
            </div>
            <Link to={"/mypage/point/4c422f94-389a-4fb5-87ed-1ae0b52ad1e8"}
                  className={"bg-white max-w-6xl pt-8 mx-auto block"}>
                <img src={"/img/point/grade_syokushin.png"} alt={"grade"}
                     className={"max-w-xl rounded-lg w-full mx-auto drop-shadow-lg px-6"}/>
            </Link>
            <div className={"bg-white max-w-6xl mx-auto py-12"}>
                <Link to={"/shop"}
                      className={"border px-12 py-3 border-red-900 bg-red-600 text-white text-xl rounded-xl inline-block"}>今すぐ注文！
                </Link>
            </div>
        </div>
    )
}

export default Top