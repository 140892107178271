import {Link, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import apiClient from "../components/apiClient";
import {LoaderCircle} from "../components/loader";
import {WaitTime} from "./shopAll";

const ItemLineUp = ({shop, items}) => {
    if (shop.name === undefined) return null
    let genre = {}
    // divide items by tag
    console.log(items)
    for (let item of items) {
        console.log(item)
        if (genre[item.tag] === undefined) genre[item.tag] = [];
        genre[item.tag].push(item)
    }
    const keys = Object.keys(genre);
    keys.sort((a, b) => {
        if (a === "650円定食") return -1;
        else if (b === "650円定食") return 1;
        else if (a === "日替わり780円定食") return -1;
        else if (b === "日替わり780円定食") return 1;
        else if (a === "週替わり定食") return -1;
        else if (b === "週替わり定食") return 1;
        return a < b;
    });
    return (
        <div className={""}>
            {keys.map(key => {
                return (
                    <div className={"mb-6"} key={key}>
                        <div className={"text-left font-bold text-xl ml-4 mt-4"}>#{key}</div>
                        {/*<div className={"flex flex-wrap justify-center"}>*/}
                        <div className={"grid grid-cols-2 lg:grid-cols-4"}>
                            {genre[key].map(item => {
                                console.log(item)
                                return (
                                    <Link to={item.soldOut ? "#" : `/shop/${shop.shopID}/${item.itemID}`} key={item.id}
                                          className={"mx-1 block p-2 w-full"}>

                                        <div
                                            className={"pt-1 border-gray-600 bg-white rounded-xl drop-shadow-lg h-full flex flex-col justify-between relative"}>
                                            {item.soldOut ? <div
                                                className={"bg-black top-0 left-0 w-full h-full rounded-xl opacity-90 absolute z-30 text-white text-xl flex justify-center items-center"}>
                                                <div>
                                                    品切れ
                                                </div>
                                            </div> : null}
                                            <div className={"m-4 h-full"}>
                                                <div>
                                                    <div className={"font-bold"}>{item.name}</div>
                                                    {item.eatIn === false && <div className={"flex justify-center"}>
                                                        <div className={"mr-1"}>店内注文</div>
                                                        <div className={"text-blue-600 font-bold"}>不可</div>
                                                    </div>}
                                                    {item.takeOut === false && <div className={"flex justify-center"}>
                                                        <div className={"mr-1"}>持ち帰り</div>
                                                        <div className={"text-blue-600 font-bold"}>不可</div>
                                                    </div>}
                                                    <div className={"mb-2"}>￥{item.price.toLocaleString()}</div>
                                                </div>
                                                {item.img === "" ? <div></div> :
                                                    <img src={item.img} alt={item.name}
                                                         className={"block rounded-xl drop-shadow w-full"}/>}
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )

}

const Cart = ({cntCart, totalPrice}) => {
    return (
        <div
            className={"fixed bottom-0 w-full h-20 bg-[#fff780] flex justify-center items-center font-bold rounded-t drop-shadow-xl"}>
            <div className={"h-full relative py-4"}>
                <img src={"/img/shopping-cart.svg"} alt={"カート"} className={"h-full"}/>
                <div
                    className={"absolute z-50 top-1/4 right-0 text-white bg-red-600 rounded-full w-6 h-6 opacity-90"}>{cntCart}
                </div>
            </div>
            <div className={"text-xl pt-1 mr-6 ml-1"}>
                ¥{totalPrice.toLocaleString()}
            </div>
            <Link to={"/cart"}
                  className={"mt-1 ml-3 p-3 border rounded border-orange-600 bg-red-500 font-normal drop-shadow text-white"}>
                カートを見る
            </Link>
        </div>
    )
}

const ShopPage = ({setTitle}) => {
    const first = useRef(false);
    const [items, setItems] = useState([]);
    const [shop, setShop] = useState({})
    const params = useParams();
    const [totalPrice, setTotalPrice] = useState(0);
    const [cntCart, setCntCart] = useState(0);
    const [authed, setAuthed] = useState(false)
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (params.id === undefined) return;
        if (setTitle === undefined) return;
        if (first.current) return;
        first.current = true;
        (async () => {
            const res = await apiClient.pub.menu(params.id);
            const data = await res.json();
            setItems(data.items)
            setShop(data.shop)
            setTitle(data.shop.name)
            console.log(data)
            await requestCart();
            setLoader(false)
        })();
    }, [params, setTitle]);

    const requestCart = async () => {
        let req = await apiClient.auth.cartGet(false);
        if (req.status === 200) {
            const res = await req.json();
            let cnt = 0;
            let price = 0;
            console.log(req, res)
            for (let item of res.items) {
                let optionsPrice = 0;
                for (let opt of item.options) {
                    optionsPrice += opt.price
                }
                price += item.num * (item.price + optionsPrice);
                cnt += item.num;
            }
            setTotalPrice(price);
            setCntCart(cnt);
            setAuthed(true);
        }
    }

    return (
        <div className={"mb-12"}>
            <LoaderCircle loader={loader} label={"取得中..."}/>
            {/*<Header title={shop.Name}/>*/}
            {shop.name === undefined ? null :
                <div className={"max-w-6xl pt-28 mx-auto bg-[#F7F7F7] px-4"}>
                    {/*<div className={"text-xl font-bold pt-4 pb-2"}>{shop.Name}</div>*/}
                    <div className={"flex items-center justify-center flex-col md:flex-row"}>
                        <img src={shop.img} className={"block mx-auto pb-2 pt-2 rounded-lg"}
                             alt={shop.name}/>
                        {shop.shopID === "4c422f94-389a-4fb5-87ed-1ae0b52ad1e8" &&
                            <Link to={"/mypage/point/4c422f94-389a-4fb5-87ed-1ae0b52ad1e8"} className={"py-3"}>
                                <img alt={"グレード"} src={"/img/point/grade_syokushin.png"}
                                     className={"max-w-xl rounded-lg w-full mx-auto"}/>
                            </Link>}
                    </div>
                    <div className={"pb-2"}>{shop.detail}</div>
                    <WaitTime unix={shop.availableDuration.start.timeID} menuID={shop.activeMenuID}/>
                    <ItemLineUp shop={shop} items={items}/>
                </div>
            }
            {authed ? <Cart cntCart={cntCart} totalPrice={totalPrice}/> : null}
        </div>
    )
}

export default ShopPage