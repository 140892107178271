import React, {useEffect, useRef, useState} from "react";
import apiClient from "../../components/apiClient";
import {LoaderCircle} from "../../components/loader";
import {Link} from "react-router-dom";
import Popup from "reactjs-popup";

const EditPopup = ({el, yes, label}) => {
    return (
        <Popup trigger={el} modal nested contentStyle={{"borderRadius": "10px"}}>
            {close =>
                (
                    <div className={"modal my-4 overflow-scroll"}>
                        <div>
                            <div className={"text-center font-bold py-4"}>{label}</div>
                            <div className={"flex items-center justify-center py-4"}>
                                <button className={"text-white bg-red-600 px-3 py-1 rounded drop-shadow"}
                                        onClick={() => {
                                            yes();
                                            close();
                                        }}>編集を終了する
                                </button>
                            </div>
                        </div>
                    </div>

                )
            }
        </Popup>
    )
}

export const Stock = ({setTitle}) => {
    const [items, setItems] = useState([]);
    const [menus, setMenus] = useState({});
    const [loader, setLoader] = useState(false);
    const first = useRef(false);
    useEffect(() => {
        if (setTitle === undefined) return;
        if (first.current) return;
        first.current = false;
        setTitle("admin");
        (async () => {
            const res = await apiClient.shop.getAllItems();
            const data = await res.json()
            console.log(data)
            let allItems = JSON.parse(JSON.stringify(data.items));
            allItems.sort((a, b) => {
                let na = Number.isNaN(Number(a.name.slice(0, 2)));
                let nb = Number.isNaN(Number(b.name.slice(0, 2)));
                if (na !== nb) {
                    return na ? -1 : 1;
                }
                if (a.tag !== b.tab) return a.tag > b.tag ? 1 : -1;
                return a.itemID > b.itemID ? 1 : -1;
                // if (Number.isNaN(Number(a.name.slice(0, 2)))) return -1;
                // return a.name < b.name ? -1 : 1;
            });
            setItems(allItems);
            let tmpMenu = {};
            for (let m of data.menus) {
                tmpMenu[m.menuID] = m;
            }
            setMenus(tmpMenu);
        })();
    }, [first, setTitle]);

    const changeStatus = async (menuID, itemID, status) => {
        setLoader(true)
        const res = await apiClient.shop.setItemSoldOut(menuID, itemID, status)
        const data = await res.json();
        console.log(data)
        setItems((prevState) => {
            return prevState.map(p => {
                if (p.itemID === itemID) {
                    p.soldOut = status;
                }
                return p
            })
        })
        setLoader(false)
    }

    return (
        <div className={"max-w-6xl mx-auto bg-[#F7F7F7] p-4 pt-4"}>
            <LoaderCircle loader={loader} label={"変更中..."}/>
            <div className={"text-left"}>
                <Link to={"/admin"} className={"inline-block p-1 border border-gray-400 border-2"}>⇐トップに戻る</Link>
            </div>
            <div className={"font-bold text-3xl my-4"}>商品一覧</div>
            <div className={"grid grid-cols-2 md:grid-cols-5"}>
                {
                    items.map(item => {
                        return (
                            <div className={"bg-white drop-shadow-md rounded-md m-1 p-2 flex flex-col justify-between"}
                                 key={item.itemID}>
                                <div>
                                    <div className={"font-bold"}>{item.name}</div>
                                    <div className={"text-sm"}>#{item.tag}</div>
                                    {item.soldOut ? <div className={"text-blue-600 font-bold"}>在庫なし</div> :
                                        <div className={"text-red-600 font-bold"}>在庫あり</div>
                                    }
                                    <div>¥{item.price.toLocaleString()}</div>
                                    <select className={"text-sm"}>{
                                        item.menuIDs.map(menuID => {
                                            let menu = menus[menuID];
                                            return (
                                                <option key={menuID} className={"inline-block"}>{menu.name}</option>
                                            )
                                        })
                                    }</select>
                                    <img className={"rounded drop-shadow my-2 mx-auto"} alt={item.name}
                                         src={item.img === "" ? "https://www.websys.edu.uec.ac.jp/pages/teacher-staff/none.png" : item.img}/>
                                </div>
                                <EditPopup el={
                                    <button className={"bg-yellow-300 px-2 py-1 rounded drop-shadow mb-1"}>商品情報を変更
                                    </button>} label={<div>
                                    <div className={"font-bold"}>{item.name}</div>
                                    <div className={"text-sm"}>#{item.tag}</div>
                                    <div>
                                        <div className={"flex items-center justify-center"}>
                                            <div className={"mr-2"}>店内注文</div>
                                            {item.eatIn ? <div className={"text-red-600"}>可</div> :
                                                <div className={"text-blue-600"}>不可</div>}
                                        </div>
                                        <div className={"flex items-center justify-center"}>
                                            <div className={"mr-2"}>持ち帰り</div>
                                            {item.takeOut ? <div className={"text-red-600"}>可</div> :
                                                <div className={"text-blue-600"}>不可</div>}
                                        </div>
                                    </div>
                                    <img alt={item.name} src={item.img}
                                         className={"mx-auto w-1/3 rounded drop-shadow my-2"}/>
                                    <div className={"m-4 font-normal"}>
                                        <div className={"font-bold"}>メニュー</div>
                                        <div className={"pt-1"}>{
                                            Object.keys(menus).map(menuID => {
                                                const menu = menus[menuID];
                                                if (menu === undefined) return null
                                                return (
                                                    <div key={menuID}
                                                         className={"mx-2 my-3 flex justify-center items-center border-b border-gray-600 pb-1"}>
                                                        <div>{menu.name}</div>
                                                        {menu.items.includes(item.itemID) ?
                                                            <button
                                                                className={"ml-4 bg-blue-600 rounded drop-shadow text-white px-2 py-1"}
                                                                onClick={() => {
                                                                    (async () => {
                                                                        const res = await apiClient.shop.deleteItemFromMenu(menuID, item.itemID);
                                                                        const data = await res.json();
                                                                        console.log(res, data, res.status === 200);
                                                                        if (res.status === 200) {
                                                                            setMenus(p => ({
                                                                                ...p,
                                                                                [data.menuID]: data
                                                                            }));
                                                                        }
                                                                    })();
                                                                }
                                                                }>メニューから削除 </button> :
                                                            <button
                                                                className={"ml-4 bg-red-600 rounded drop-shadow text-white px-2 py-1"}
                                                                onClick={() => {
                                                                    (async () => {
                                                                        const res = await apiClient.shop.addItemToMenu(menuID, item.itemID);
                                                                        const data = await res.json();
                                                                        console.log(res, data);
                                                                        if (res.status === 200) {
                                                                            setMenus(p => ({
                                                                                ...p,
                                                                                [data.menuID]: data
                                                                            }));
                                                                        }
                                                                    })();
                                                                }
                                                                }>メニューに追加
                                                            </button>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }</div>
                                    </div>
                                </div>} yes={() => {
                                    console.log("!!")
                                }}/>
                                {item.soldOut ?
                                    <button className={"bg-red-600 text-white rounded drop-shadow px-2 py-1"}
                                            onClick={() => {
                                                (async () => {
                                                    await changeStatus(item.menuIDs[0], item.itemID, false)
                                                })()
                                            }}
                                    >在庫ありに変更する
                                    </button> :
                                    <button className={"bg-blue-600 text-white rounded drop-shadow px-2 py-1"}
                                            onClick={() => {
                                                (async () => {
                                                    await changeStatus(item.menuIDs[0], item.itemID, true)
                                                })()
                                            }}
                                    >品切れに変更する
                                    </button>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}