import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDropzone} from "react-dropzone";
import apiClient from "../../components/apiClient";
import Popup from "reactjs-popup";
import {Link} from "react-router-dom";

const zoneStyleNormal = {
    border: "1px dotted #888"
}

const zoneStyleDrag = {
    border: "1px solid #0703fc",
    transition: 'border .1s ease-in-out'
}

const RegisterPopup = ({open, closePop}) => {
    return (
        <Popup open={open} modal nested>
            {close =>
                (
                    <div className={"modal my-4"}>
                        <div className={"text-center font-bold py-4"}>商品を登録しました！</div>
                        <div className={"flex items-center justify-center py-4"}>
                            <button className={"text-white bg-red-600 px-3 py-1 rounded"} onClick={() => {
                                closePop();
                                close();
                            }}>了解しました
                            </button>
                        </div>
                    </div>
                )
            }
        </Popup>
    )
}

export const ManageItems = ({setTitle}) => {
    const first = useRef(false);
    // image
    const [itemImage, setItemImage] = useState("");
    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
        (async () => {
            console.log('acceptedFiles:', acceptedFiles);

            const res = await apiClient.shop.fileUpload2(acceptedFiles);
            setItemImage(res.data.url);
            setContents(p => ({...p, img: res.data.url}))
        })()
    }, []);
    const {getRootProps, getInputProps, isDragActive, open} = useDropzone({onDrop, maxFiles: 1});
    const zoneStyle = useMemo(() => (
        {...(isDragActive ? zoneStyleDrag : zoneStyleNormal)}
    ), [isDragActive]);

    // form
    const [errors, setErrors] = useState({});
    const [canSubmit, setCanSubmit] = useState(true);
    const okSend = useRef(false);
    const [contents, setContents] = useState({"options": [], "choices": {},});
    const [openPopup, setOpenPopup] = useState(false);
    // useEffect(() => {
    //     console.log("CHANGED", contents)
    // }, [contents])
    const options = useRef({});

    const err = (name, value) => {
        setCanSubmit(false);
        okSend.current = false;
        console.log("ERROR", name, value)
        setErrors((p) => ({...p, [name]: <div className={"text-red-600 font-bold"}>{value}</div>}))
    }
    const onSubmit = data => {
        setErrors((p) => {
            let n = {};
            for (let key in p) {
                n[key] = "";
            }
            return n;
        })
        setCanSubmit(true);
        okSend.current = true;
        contents.name === "" && err("name", "商品名を入力してください");
        contents.detail === "" && err("detail", "詳細を入力してください");
        console.log(Number.isInteger(Number(contents.price)), contents.price);
        (contents.price === "" || !Number.isInteger(Number(contents.price))) && err("price", "半角数字で入力してください。");
        contents.tag === "" && err("tag", "タグを入力してください");
        itemImage === "" && err("img", "画像をアップロードしてください");

        for (let opt of contents.options) {
            let item = options.current[opt];
            item.name === "" && err(`option${opt}-name`, "オプション名を入力してください。");
            item.default === "" && err(`option${opt}-default`, "どれか一つの選択肢をデフォルトに選んでください。")
            console.log("option", item)
            // choices
            for (let choiceIndex in item.choices) {
                const cho = item.choices[choiceIndex];
                cho.name === "" && err(`${choiceIndex}-name`, "選択肢の名前を入力してください。");
                (cho.price === "" || !Number.isInteger(Number(cho.price))) && err(`${choiceIndex}-price`, "半角数字で入力してください。");
            }
        }
        console.log("contents", contents)
        console.log("options", options.current)
        // return;
        if (okSend.current) {
            let data = {
                ...contents,
                options: [],
            }
            delete data.choices;
            data.price = Number(data.price);
            for (let opt in options.current) {
                const item = options.current[opt];
                let d = {
                    name: item.name,
                    choices: [],
                    defaultChoice: item.default,
                };
                for (let cho in item.choices) {
                    const choice = item.choices[cho];
                    d.choices.push({
                        choiceID: cho,
                        name: choice.name,
                        price: Number(choice.price),
                        takeOut: choice.takeOut,
                        eatIn: choice.eatIn,
                    })
                }
                data.options.push(d);
            }
            // console.log("SEND", data);
            setOpenPopup(true);
            (async () => {
                const res = await apiClient.shop.registerItem(data);
                console.log(await res.json());
            })();
        }
    };

    const Options = () => {
        const OptionFields = ({index}) => {
            return (
                <div className={"border-l border-l-gray-600 my-3 pl-3"}>
                    <div className={"py-2 flex items-center"}>
                        <div className={"mr-4"}>選択肢一覧</div>
                        <button className={"px-1 py-1 border-gray-600 rounded border text-sm"} onClick={() => {
                            const t = new Date().getTime();
                            setContents(p => {
                                let tmp = JSON.parse(JSON.stringify(p));
                                tmp.choices[index].push(t);
                                return tmp;
                            });
                            options.current[index].choices[t] = {
                                name: "",
                                eatIn: true,
                                takeOut: true,
                                price: 0,
                            };
                        }}>選択肢を増やす
                        </button>
                    </div>
                    {contents.choices[index].map(choiceIndex => {
                        const item = options.current[index].choices;
                        // console.log("Option Item:", item)
                        return (
                            <div key={choiceIndex} className={"mb-2"}>
                                <div>
                                    <label htmlFor={choiceIndex}>選択肢</label>
                                    <button onClick={() => {
                                        setContents(p => ({
                                            ...p,
                                            choices: {
                                                ...p.choices,
                                                [index]: p.choices[index].filter(v => v !== choiceIndex)
                                            }
                                        }))
                                        if (options.current[index].default === String(choiceIndex)) {
                                            options.current[index].default = "";
                                        }
                                        delete options.current[index].choices[choiceIndex];
                                    }} className={"text-sm border border-gray-600 rounded px-1 ml-3"}>この選択肢を削除する
                                    </button>
                                    <span className={"text-sm flex items-center ml-2"}>
                                    <input type={"radio"} id={choiceIndex} name={`option${index}-default`}
                                           value={choiceIndex} {...register(index, "option", "default")}
                                           defaultChecked={options.current[index].default === String(choiceIndex)}
                                    />
                                        <label htmlFor={choiceIndex}
                                               className={"inline-block"}>この選択肢をデフォルトにする</label>
                                </span>
                                    <span className={"text-sm flex items-center ml-2"}>
                                        {/*{console.log(options.current, index, options.current[index].choices, choiceIndex)}*/}
                                        <input type={"checkbox"}
                                               defaultChecked={options.current[index].choices[choiceIndex].eatIn} {...register(`${choiceIndex}`, "choice-check", "eatIn", index, true)}/>
                                    <div className={"inline-block mx-1"}>店内注文可能</div>
                                    <input type={"checkbox"} className={"ml-3"}
                                           defaultChecked={options.current[index].choices[choiceIndex].takeOut} {...register(`${choiceIndex}`, "choice-check", "takeOut", index, true)}/>
                                    <div className={"inline-block mx-1"}>持ち帰り注文可能</div>
                                        {errors[`${choiceIndex}-style`]}
                                </span>
                                </div>
                                <div className={"inline-block w-3/4 px-3"}>
                                    <input id={index + "-name"}
                                           defaultValue={options.current[index].choices[choiceIndex].name}
                                           placeholder={"少なめ (ラベル)"} {...register(choiceIndex, "choice", "name", index)}
                                           className={"border border-gray-600 rounded px-3 py-1 w-full"}/>
                                    {errors[`${choiceIndex}-name`]}
                                </div>
                                <div className={"inline-block w-1/4 px-3"}>
                                    <input id={index + "-price"}
                                           defaultValue={options.current[index].choices[choiceIndex].price}
                                           placeholder={"50 (金額)"} {...register(choiceIndex, "choice", "price", index)}
                                           className={"border border-gray-600 rounded px-3 py-1 w-full"}/>
                                    {errors[`${choiceIndex}-price`]}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        }

        return (
            <div>
                <button className={"border border-gray-600 py-1 px-3 rounded text-sm"} onClick={() => {
                    const t = new Date().getTime();
                    setContents(p => {
                        let x = JSON.parse(JSON.stringify(p));
                        x.options = [...x.options, t];
                        x.choices[t] = [];
                        return x;
                    });
                    options.current[t] = {
                        index: t,
                        name: "",
                        default: "",
                        choices: {},
                    }
                }}>オプションを追加
                </button>
                {contents.options.map(index => {
                    const item = options.current[index];
                    return (
                        <div key={index} className={"my-2 border-l-gray-600 border-l pl-3 ml-3"}>
                            <div>
                                <span>オプション名</span>
                                <button className={"ml-4 border border-gray-600 px-1 rounded text-sm"} onClick={() => {
                                    delete options.current[item.index];
                                    setContents(p => ({...p, options: p.options.filter(v => v !== item.index)}));
                                }}>オプションを削除する
                                </button>
                            </div>
                            {console.log(options.current, index, item, options.current[Number(index)])}
                            <input placeholder={"ご飯の量"}
                                   className={"px-3 py-1 border-gray-600 border rounded w-full"} {...register(`${item.index}`, "option", "name")}
                                   defaultValue={item.name}/>
                            {errors[`option${item.index}-name`]}
                            {errors[`option${index}-default`]}
                            <OptionFields index={item.index}/>
                        </div>
                    )
                })}
            </div>
        )
    }

    const register = (name, type = "normal", value = false, master = -1, dv = true) => {
        if (type === "normal") {
            if (contents[name] === undefined) setContents((p) => ({...p, [name]: ""}))
            return {
                onChange: (event) => {
                    setContents((p) => ({...p, [name]: event.target.value}))
                }
            }
        } else if (type === "check") {
            if (contents[name] === undefined) setContents((p) => ({...p, [name]: value}))
            return {
                onChange: (event) => {
                    setContents((p) => ({...p, [name]: event.target.checked}))
                }
            }
        } else if (type === "option") {
            let already = options.current[name] !== undefined;
            if (!already) options.current[name] = {
                index: name,
                default: "",
                name: "",
            };
            return {
                onChange: (event) => {
                    options.current[name][value] = event.target.value;
                }
            }
        } else if (type === "choice") {
            let already = options.current[master]["choices"][name] !== undefined;
            if (!already) options.current[master]["choices"][name] = {
                index: name,
                name: "",
            }
            return {
                onChange: (event) => {
                    options.current[master]["choices"][name][value] = event.target.value;
                }
            }
        } else if (type === "choice-check") {
            let already = options.current[master]["choices"][name] !== undefined;
            if (!already) options.current[master]["choices"][name] = {
                index: name,
                name: "",
            }
            if (options.current[master]["choices"][name][value] === undefined) options.current[master]["choices"][name][value] = dv;
            return {
                onChange: (event) => {
                    options.current[master]["choices"][name][value] = event.target.checked;
                }
            }
        }
    }

    const useTemplate = (event) => {
        const value = event.target.value;
        console.log(value)
        if (value === "麻婆豆腐") {
            setContents({
                "options": [
                    1675529297248,
                    1675529303840,
                    1675529304256
                ],
                "choices": {
                    "1675529297248": [
                        1675529301840,
                        1675529302176,
                        1675529302536
                    ],
                    "1675529303840": [
                        1675529311177,
                        1675529312232,
                        1675529339768,
                        1675529340128
                    ],
                    "1675529304256": [
                        1675529313080,
                        1675529313408,
                        1675529347200
                    ]
                },
                "name": "麻婆豆腐",
                "detail": "まずはコレ！程よい辛さの激ウマ麻婆豆腐",
                "price": "650",
                "eatIn": true,
                "takeOut": true,
                "tag": "650円定食",
                "img": "https://file.wannabuy.click/4c422f94-389a-4fb5-87ed-1ae0b52ad1e8/l5ma4NiVzZCL27taMhUAZkh3.jpeg"
            });

            options.current = {
                "1675529297248": {
                    "index": 1675529297248,
                    "name": "ご飯の量",
                    "default": "1675529302176",
                    "choices": {
                        "1675529301840": {
                            "name": "少なめ",
                            "eatIn": true,
                            "takeOut": true,
                            "price": 0
                        },
                        "1675529302176": {
                            "name": "普通",
                            "eatIn": true,
                            "takeOut": true,
                            "price": 0
                        },
                        "1675529302536": {
                            "name": "多め",
                            "eatIn": true,
                            "takeOut": true,
                            "price": 0
                        }
                    }
                },
                "1675529303840": {
                    "index": 1675529303840,
                    "name": "辛さ",
                    "default": "1675529311177",
                    "choices": {
                        "1675529311177": {
                            "name": "普通",
                            "eatIn": true,
                            "takeOut": true,
                            "price": 0
                        },
                        "1675529312232": {
                            "name": "大辛",
                            "eatIn": true,
                            "takeOut": true,
                            "price": 0
                        },
                        "1675529339768": {
                            "name": "中辛",
                            "eatIn": true,
                            "takeOut": true,
                            "price": 0
                        },
                        "1675529340128": {
                            "name": "小辛",
                            "eatIn": true,
                            "takeOut": true,
                            "price": 0
                        }
                    }
                },
                "1675529304256": {
                    "index": 1675529304256,
                    "name": "にんにく",
                    "default": "1675529313408",
                    "choices": {
                        "1675529313080": {
                            "name": "多め",
                            "eatIn": true,
                            "takeOut": true,
                            "price": 0
                        },
                        "1675529313408": {
                            "name": "普通",
                            "eatIn": true,
                            "takeOut": true,
                            "price": 0
                        },
                        "1675529347200": {
                            "name": "少なめ",
                            "eatIn": true,
                            "takeOut": true,
                            "price": 0
                        }
                    }
                }
            }
        }

    }

    useEffect(() => {
        if (first.current) return;
        first.current = true;
        setTitle("admin")
    }, [setTitle])
    return (
        <div className={"bg-white max-w-6xl mx-auto p-8"}>
            <RegisterPopup open={openPopup} closePop={() => {
                setOpenPopup(false)
            }}/>
            <div className={"text-left"}>
                <Link to={"/admin"} className={"inline-block p-1 border border-gray-400 border-2"}>⇐トップに戻る</Link>
            </div>
            <div className={"text-xl font-bold"}>商品登録</div>
            <div>新しい商品を登録します</div>
            <div>
                <div className={"text-left"}>
                    <div className={"my-4"}>
                        <label className={"block"} htmlFor={"template"}>テンプレートを選択する(上書きされます)</label>
                        <select id={"template"} className={"py-1 px-1 border border-gray-600 w-full rounded"}
                                onChange={useTemplate}>
                            <option>テンプレートを使用する</option>
                            <option value={"麻婆豆腐"}>麻婆豆腐</option>
                        </select>
                    </div>
                    <div className={"my-4"}>
                        <label htmlFor={"item-name"}>商品名 (必須)</label>
                        <input id={"item-name"} className={"border border-gray-600 py-1 px-3 rounded block w-full"}
                               placeholder={"麻婆豆腐"} {...register("name")} defaultValue={contents.name}/>
                        {errors.name}
                    </div>
                    <div className={"my-4"}>
                        <label htmlFor={"item-detail"}>詳細 (必須)</label>
                        <input id={"item-detail"} className={"border border-gray-600 py-1 px-3 rounded block w-full"}
                               placeholder={"人気No.1のおすすめ商品！"} {...register("detail")}
                               defaultValue={contents.detail}/>
                        {errors.detail}
                    </div>
                    <div className={"my-4"}>
                        <label htmlFor={"item-price"}>料金 (必須)</label>
                        <input id={"item-price"} className={"border border-gray-600 py-1 px-3 rounded block w-full"}
                               placeholder={"680"} {...register("price")} defaultValue={contents.price}/>
                        {errors.price}
                    </div>
                    <div className={"my-4"}>
                        <div>注文スタイル (必須)</div>
                        <div className={"inline-block mr-4"}>
                            <input id={"item-EatIn"} type={"checkbox"} className={"mx-1"}
                                   defaultChecked={true} {...register("eatIn", "check", true)}/>
                            <label htmlFor={"item-EatIn"}>店内注文可能</label>
                        </div>
                        <div className={"inline-block mr-4"}>
                            <input id={"item-TakeOut"} type={"checkbox"} className={"mx-1"}
                                   defaultChecked={true} {...register("takeOut", "check", true)}/>
                            <label htmlFor={"item-TakeOut"}>持ち帰り注文可能</label>
                        </div>
                        {errors.style}
                    </div>
                    <div className={"my-4"}>
                        <label htmlFor={"item-tag"}>タグ (必須)</label>
                        <input id={"item-tag"} className={"border border-gray-600 py-1 px-3 rounded block w-full"}
                               placeholder={"日替わり780円定食"} {...register("tag")} defaultValue={contents.tag}/>
                        {errors.tag}
                    </div>
                    <div className={"my-4"}>
                        <label htmlFor={"item-price"}>オプション</label>
                        <Options/>
                    </div>
                    <div className={"my-4 text-left"}>
                        <label htmlFor={"item-name"}>商品画像 (必須)</label>
                        <div {...getRootProps()} style={zoneStyle}
                             className={"w-full py-4 flex items-center justify-center flex-col"}>
                            <input  {...getInputProps()} name={"item-img"}/>
                            <img alt={"画像をアップロードしてください"} className={"my-3 border border-gray-600"} src={itemImage}/>
                            {isDragActive ?
                                <div>画像をアップロードします</div> :
                                <div>商品画像をドラッグ&ドロップ</div>
                            }
                            <button type={"button"}
                                    className={"border p-1 mt-4 text-sm border-gray-600 rounded"}>ファイルを選択
                            </button>
                        </div>
                        {errors.img}
                    </div>
                    <input type={"submit"}
                           className={"p-3 border border-gray-600 rounded drop-shadow w-full bg-red-600 text-white"}
                           onClick={onSubmit}
                           value={"登録をする"}/>
                    {!canSubmit && <div className={"text-red-600 font-bold"}>入力内容を修正してください。</div>}
                </div>
            </div>

        </div>
    )
}