import {Link, useLocation, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import apiClient from "../../components/apiClient";
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import Popup from "reactjs-popup";
import {LoaderCircle} from "../../components/loader";

const ConfirmPopup = ({el, yes, label}) => {
    const [text, setText] = useState("");
    return (
        <Popup trigger={el} modal nested contentStyle={{"borderRadius": "10px"}}>
            {close =>
                (
                    <div className={"modal my-4"}>
                        {text === "" ?
                            <div>
                                <div className={"text-center font-bold py-4"}>{label}</div>
                                <div className={"flex items-center justify-center py-4"}>
                                    <button className={"text-white bg-gray-600 px-3 py-1 rounded mr-4"}
                                            onClick={close}>いいえ
                                    </button>

                                    <button className={"text-white bg-red-600 px-3 py-1 rounded drop-shadow"}
                                            onClick={() => {
                                                (async () => {
                                                    const [res, data] = await yes();
                                                    if (res.status === 200) {
                                                        setText("クーポンを使用しました！")
                                                    } else {
                                                        setText(data.message);
                                                    }
                                                    // close();
                                                })();
                                            }}>使用済みにする
                                    </button>
                                </div>
                            </div> : <div className={"text-red-600 text-xl font-bold text-center"}>{text}</div>}
                    </div>

                )
            }
        </Popup>
    )
}


export const Point = ({setTitle}) => {
    const params = useParams();
    const first = useRef(false);
    const [point, setPoint] = useState({});
    const [shop, setShop] = useState({});
    const [tweetText, setTweetText] = useState("");
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (params === undefined) return;
        if (setTitle === undefined) return;
        if (first.current) return;
        first.current = true;
        setTitle("ポイントカード");
        (async () => {
            const res = await apiClient.auth.pointGet(params.ShopID);
            const data = await res.json();
            if (res.status === 200) {
                setPoint(data.point);
                setShop(data.shop);
                console.log(data)
            } else {
                let res = await apiClient.pub.menu(params.ShopID);
                let data = await res.json();
                setShop(data.shop);
            }
        })();
    }, [first, params, setTitle]);

    useEffect(() => {
        if (params.shopID === undefined) return;
        if (Object.keys(point).length === 0) return;
        setTweetText(encodeURI("おめでとうございます！\n\n" +
            `あなたの食神レベルは.....\n【${point.grade.label}】\n\n` +
            `今までの注文回数は「${point.orders.length}回」！\n\n` +
            "----------------------------\n" +
            `持ち帰り/店内注文サービスならWannaBuy(ワナバイ)\n${window.location.protocol}//${window.location.host}/mypage/point/${params.ShopID}`))
    }, [params, params.ShopID, point])

    return (
        <div className={"max-w-6xl mx-auto"}>
            <div className={"pt-28 pb-4 bg-[#F7F7F7]"}>
                <LoaderCircle loader={loader} label={"リクエスト中..."}/>
                <div>
                    <div
                        className={"mx-4 md:mt-4 md:mx-16 mt-0 p-4  border drop-shadow-lg rounded-xl bg-[#FFED00]s bg-white"}>
                        {!!Object.keys(shop).length &&
                            <div className={"flex justify-center md:justify-between items-center"}>
                                <div
                                    className={"h-16 md:h-20 text-left px-4 py-2 flex items-center justify-center md:justify-start"}>
                                    <img src={shop.img} alt={shop.name}
                                         className={"h-full inline-block mr-6 rounded-full block"}/>
                                    <div className={"text-2xl font-bold inline-block tracking-widest"}>{shop.name}</div>
                                </div>
                                <div className={"flex items-center justify-center md:justify-end hidden md:block"}>
                                    <div className={"flex mr-4 items-center bg-[#FAED04]"}>
                                        <img alt={"WannaBuy"} src={"/img/logo.png"}
                                             className={"md:h-20 mx-1"}/>
                                    </div>
                                </div>
                            </div>}
                        <hr className={"mb-2 md:my-3 border-gray-500"}/>
                        {!Object.keys(point).length &&
                            <Link to={"/mypage/register"} className={"text-blue-600"}>ログインするとポイントがたまります</Link>}
                        {!!Object.keys(point).length &&
                            <div className={"grid grid-cols-1 md:grid-cols-2"}>
                                <div className={"mx-auto text-center flex items-center justify-center w-full"}>
                                    <img src={`https://file.wannabuy.click/points/${point.grade.imgFile}`} alt={"user"}
                                         className={"rounded-xl drop-shadow w-full mx-8"}/>
                                </div>
                                <div
                                    className={"flex items-center justify-center flex-col font-semibold"}>
                                    <div
                                        className={"mt-4 md:mb-2 flex items-end border-b0 border-black pl-3 flex-col items-center"}>
                                        <div className={"flex items-center"}>
                                            <span className={"mx-2"}>あなたは</span>
                                            <a target={"_blank"} rel={"noopener noreferrer"}
                                               href={"https://twitter.com/intent/tweet?text=" + tweetText}>
                                                <img src={"/img/point/twitter.svg"} alt={"twitter"}
                                                     className={"h-7 inline-block"}/>
                                            </a>
                                        </div>
                                        <div>
                                            <span
                                                className={"text-4xl md:text-5xl  font-semibold tracking-wide"}>【<span>{point.grade.label}</span>】
                                            </span>

                                        </div>
                                    </div>
                                    <div className={"flex items-end pr-4"}>
                                        <div>これまでの注文</div>
                                        <div
                                            className={"text-4xl mx-3 font-semibold tracking-widest text-yellow-200"}>
                                            <span className={"fuchi"}>{point.orders.length}</span>
                                        </div>
                                        <div>回</div>
                                    </div>
                                    <div className={"flex items-end text-base mt-3"}>
                                        <div>あと</div>
                                        <div
                                            className={"text-2xl mx-1 font-semibold text-red-700"}>{point.nextReward.order - point.orders.length}
                                        </div>
                                        <div>回注文で</div>
                                        <div className={"text-2xl mx-1 font-semibold"}>{point.nextReward.discount}</div>
                                        <div>円クーポンゲット！</div>
                                    </div>
                                    <div className={"w-full"}>
                                        <div className={"mx-auto pt-6 mx-4"}>
                                            <CalendarHeatmap
                                                startDate={new Date(new Date().getTime() - 6 * 30 * 24 * 60 * 60 * 1000)}
                                                endDate={new Date()}
                                                showOutOfRangeDays={true}
                                                values={point.orders.map(order => {
                                                    return {date: order}
                                                })}
                                                classForValue={(value) => {
                                                    if (!value) {
                                                        return 'color-empty';
                                                    }
                                                    return `color-scale-1`;
                                                    // return `color-scale-${Math.min(1, value.count)}`;
                                                }
                                                }
                                                titleForValue={(value) => {
                                                    if (value === null) {
                                                        return "注文なし"
                                                    } else {
                                                        const date = new Date(value.date);
                                                        return `${date.toLocaleDateString()}に注文`
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                    <div className={"my-4 mx-5 drop-shadow"}>
                        <img alt={"グレード"} src={"/img/point/grade_syokushin.png"}
                             className={"max-w-xl rounded-lg w-full mx-auto"}/>
                    </div>
                    {!!Object.keys(point).length &&
                        <div
                            className={"text-left mx-4 md:mx-16 text-center border drop-shadow-lg bg-white p-6 rounded mt-2 rounded-xl"}>
                            <div className={"font-bold text-xl mt-4 mb-8"}>獲得したクーポン一覧</div>
                            <div className={"grid grid-cols-1 md:grid-cols-3 mx-auto"}>
                                {point.rewards.map(reward =>
                                    <div className={"relative"}>
                                        {reward.use &&
                                            <div
                                                className={"p-1 h-full w-full absolute t-0 z-40 opacity-70 flex items-center justify-center"}>
                                                <div
                                                    className={"h-full w-full bg-black text-white flex items-center justify-center text-2xl font-bold"}>使用済み
                                                </div>

                                            </div>}
                                        <ConfirmPopup el={
                                            <div className={"w-[17em] relative mx-auto my-2 cursor-pointer"}>
                                                <img alt={"coupon"} src={"/img/point/coupon.png"}
                                                     className={"drop-shadow-lg"}/>

                                                <div
                                                    className={"absolute top-0 flex w-[11em] h-full items-center justify-center flex-col text-white"}>
                                                    <div className={"drop-shadow-xl text-lg tracking-wide"}>
                                                        <span className={"font-bold"}>{reward.name}</span>
                                                    </div>
                                                    <div className={"mt-4 text-lg tracking-widest"}>
                                                    <span
                                                        className={"text-2xl font-bold mr-1"}>{reward.discount}円</span>引き
                                                    </div>
                                                </div>
                                            </div>
                                        } yes={async () => {
                                            setLoader(true);
                                            const res = await apiClient.auth.useToken(shop.shopID, reward.token);
                                            const data = await res.json();
                                            if (res.status === 200) {
                                                setPoint(data);
                                            }
                                            setLoader(false);
                                            return [res, data];
                                        }} label={<div>
                                            <div className={"text-xl"}>食神餃子王</div>
                                            <div className={"text-red-600 text-3xl"}>「100円引き」クーポン</div>
                                            <br/>
                                            <div>このクーポンを本当に使用済みにしますか？</div>
                                            <div>この操作は取り消しできません。</div>
                                            <div className={"text-red-600"}>必ずこの画面を店員に見せながら使用済みを押してください。</div>
                                        </div>}/>
                                    </div>
                                )}
                            </div>
                        </div>}
                    <div className={"my-5 text-left text-sm mx-4 md:mx-16"}>
                        <div>※1 1日に複数回注文しても1回注文したとカウントされます。</div>
                        <div>※2 クーポンを使用するには、店員に画面を見せながら「使用済みにする」を押してください。</div>
                        <div>※3 翌日午前6:00にポイントが更新されます。</div>
                        <div>※4 キャンセルした注文はポイント加算対象外です。</div>
                        <div>※5 500円未満の注文はポイント加算対象外です。</div>
                        <div>※6 注文5,10,20,30,...回ごとに100円引きクーポンを発行します。</div>
                    </div>
                </div>
            </div>
        </div>
    )
}