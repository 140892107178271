import React, {useEffect, useState} from "react";
import apiClient from "../components/apiClient";
import Header from "../components/Header";
import {Alert} from "@mui/material";

const pad = (base) => {
    return base.toString().padStart(2, '0')
}

const Attention = () => {
    return (
        <div className={"mt-4"}>
            <Alert severity={"warning"} className={"drop-shadow"}>注文のキャンセル・変更に関しては店舗へ直接連絡してください。</Alert>
        </div>
    )
}

export const OrdersList = ({
                               type, orders, AddComp = () => {
    }
                           }) => {

    const OrderStatus = ({status}) => {
        if (status === 0) {
            return null
            // return <div className={"text-purple-700 font-bold"}>受け取り待ち</div>
        } else if (status === 1) {
            return <div className={"text-blue-600 font-bold"}>キャンセル済み</div>
        } else if (status === 2) {
            return null
            // return <div className={"text-red-600 font-bold"}>受け取り済み</div>
        }
    }
    const orderColor = (status) => {
        if (type === "user") return ""
        if (status === 0) {
            return "border-purple-700"
        } else if (status === 1) {
            return "border-blue-600"
        } else if (status === 2) {
            return "border-red-600"
        }
    }
    return (
        <div>
            {orders.length === 0 ? null : orders.map(order => {
                const reserveDate = new Date(order.reserveTime);
                const reserveDateString = `${reserveDate.getFullYear()}/${pad(reserveDate.getMonth() + 1)}/${pad(reserveDate.getDate())} ${pad(reserveDate.getHours())}:${pad(reserveDate.getMinutes())}`
                const border = "border border-4 " + orderColor(order.Status) + ""
                return (
                    <div
                        className={"bg-white rounded-lg drop-shadow-lg m-4 p-5 mx-auto text-center " + border}
                        key={order.orderID}>
                        <div
                            className={"flex justify-start items-start flex-col lg:flex-row lg:items-end"}>
                            <div className={"pr-2 text-xl font-bold"}>{order.shop.name}</div>
                            <div className={"flex items-end"}>
                                <div className={"pr-2"}>注文番号</div>
                                <div className={"text-red-600 font-bold text-2xl pr-2"}>{order.orderID}</div>
                            </div>
                            <div className={"flex"}>
                                <div>店舗電話番号：</div>
                                <a className={"text-blue-600"} href={"tel:" + order.shop.phone}>{order.shop.phone}</a>
                            </div>
                            <OrderStatus status={order.status}/>
                        </div>
                        <div className={"flex justify-start"}>
                            <div>予約タイプ：</div>
                            <div className={"font-bold"}>{order.type === "EatIn" ? "店内予約" : "持ち帰り予約"}</div>
                        </div>
                        {order.type === "EatIn" ? null :
                            <div className={"flex justify-start"}>
                                <div>受け取り予定日時：</div>
                                <div className={"font-bold"}>{reserveDateString}</div>
                            </div>
                        }
                        <div className={"flex justify-start"}>
                            <div>支払金額：</div>
                            <div className={"font-bold"}>¥{order.totalPrice.toLocaleString()}</div>
                        </div>
                        <hr className={"my-1"}/>
                        {AddComp(order)}
                        <div>
                            <div className={"text-left font-bold "}>商品一覧</div>
                            <div className={"lg:grid lg:grid-cols-2"}>
                                {order.items.map(item => {
                                    return (
                                        <div
                                            className={"text-left p-3 my-1 drop-shadow-md border-gray-400 border rounded bg-white lg:m-2"}
                                            key={JSON.stringify(item)}>
                                            <div className={"flex"}>
                                                <div className={"font-bold"}>{item.name}</div>
                                            </div>
                                            <div className={"flex flex-row"}>
                                                <div className={"w-full basis-3/4"}>
                                                    {item.options.map(opt => {
                                                        return (
                                                            <div className={"flex ml-1"} key={opt.optionID}>
                                                                ・
                                                                <div>{opt.optionLabel}</div>
                                                                <div className={"mx-1"}>-</div>
                                                                <div>{opt.choiceLabel}</div>
                                                                <div>(¥{opt.price})</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className={"basis-1/4"}>
                                                    <img alt={item.name} src={item.img} className={"w-full rounded"}/>
                                                </div>
                                            </div>
                                            {(() => {
                                                let optionsPrice = 0;
                                                for (let opt of item.options) {
                                                    optionsPrice += opt.price
                                                }
                                                return (
                                                    <div
                                                        className={"ml-2"}>¥{item.price + optionsPrice} ✕ <span
                                                        className={"font-bold"}>{item.num}個</span>
                                                        =
                                                        ¥{((item.price + optionsPrice) * item.num).toLocaleString()}
                                                    </div>
                                                )
                                            })()}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export const Order = () => {
    const [orders, setOrders] = useState([]);
    useEffect(() => {
        (async () => {
            const res = await apiClient.auth.orderGet(true)
            const data = await res.json();
            setOrders(data.orders)
            console.log(data)
        })()
    }, [])
    return (
        <div>
            <Header title={"注文履歴"}/>
            <div className={"pt-24 px-4 max-w-6xl mx-auto"}>
                <Attention/>
                <OrdersList orders={orders} type={"user"}/>
            </div>
        </div>
    )
}


