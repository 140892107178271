import {useEffect, useState} from "react";
import apiClient from "../components/apiClient";
// import apiClient from "../components/api";

const Debug = () => {
    const [health, setHealth] = useState("loading...");
    const [shop, setShop] = useState("loading...");
    const [menu, setMenu] = useState("loading...");
    const [item, setItem] = useState("loading...");
    const api = {};

    useEffect(() => {
        (async () => {
            let res = await apiClient.pub.health();
            setHealth(res.message);

            res = await apiClient.pub.shopGet();
            console.log(res)
            setShop(res.shops[0].Name);

            let shopID = res.shops[0].ShopID
            console.log("shopID", shopID)
            res = await apiClient.pub.menu(shopID);
            console.log(res)
            setMenu(res.shop.Name)

            res = await apiClient.pub.item(shopID, res.items[0].ItemID);
            console.log(res)
            setItem(res.item.Name);

        })();
    }, []);

    return (
        <div>
            <h1 className={"text-xl"}>Debug page</h1>
            <div className={"border border-black m-4 p-4 flex flex-col"}>
                <div>ヘルスチェック</div>
                <hr/>
                <div>{health}</div>
            </div>
            <div className={"border border-black m-4 p-4 flex flex-col"}>
                <div>shop</div>
                <hr/>
                <div>{shop}</div>
            </div>
            <div className={"border border-black m-4 p-4 flex flex-col"}>
                <div>menu</div>
                <hr/>
                <div>{menu}</div>
            </div>
            <div className={"border border-black m-4 p-4 flex flex-col"}>
                <div>item</div>
                <hr/>
                <div>{item}</div>
            </div>
            <button onClick={() => {
                (async () => {
                    const res = await apiClient.auth.check()
                    console.log(res)
                })()
            }}>Auth Check
            </button>
        </div>
    )
}

export default Debug;