import {useEffect, useRef} from "react";
import ReactMarkdown from "react-markdown";

const terms = `
# WannaBuy利用規約


この利用規約（以下、「本規約」といいます。）は、日本テクノトレード株式会社（以下、「当社」といいます。）がこのウェブサイト上で提供するWannaBuy（以下、「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
###  第1条（適用）
1. 本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
2. 当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下,「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
3. 本規約の定めが前項の個別規定の定めと矛盾する場合には、個別規定において特段の定めなき限り、個別規定の定めが優先されるものとします。
### 第2条（利用登録）
4. 本サービスにおいては、登録希望者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれに対する承認を登録希望者に通知することによって、利用登録が完了するものとします。
5. 利用者は、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
6. 当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。 
   1. 利用登録の申請に際して虚偽の事項を届け出た場合
   2. 本規約に違反するおそれがあると当社が判断した場合
   3. 本規約に違反したことがある者からの申請である場合
   4. 反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
   5. その他、当社が利用登録を相当でないと判断した場合
### 第3条（ユーザーIDおよびパスワードの管理）
7. ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを管理するものとします。
8. ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。
9. ユーザーID及びパスワードが第三者に使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。
### 第4条（売買契約）
10. 本サービスにおいては、ユーザーが当社に対して購入の申し込みをし、これに対して当社が当該申し込みを承諾した旨の通知をすることによって売買契約が成立するものとします。なお、当該商品の所有権は、当社が商品を配送業者に引き渡したときに、ユーザーに移転するものとします。
11. 当社は、ユーザーが以下のいずれかの事由に該当する場合には、当該ユーザーに事前に通知することなく、前項の売買契約を解除することができるものとします。 
   1. ユーザーが本規約に違反した場合
   2. 届け先不明や長期の不在のため商品の配送が完了しない場合
   3. その他当社とユーザーの信頼関係が損なわれたと認める場合
12. 本サービスに関する決済方法、配送方法、購入の申し込みのキャンセル方法、または返品方法等については、別途当社が定める方法によります。
### 第5条（知的財産権）
13. 本サービスによって提供される商品写真その他のコンテンツ（以下「コンテンツ」といいます）の著作権又はその他の知的所有権は,当社及びコンテンツ提供者などの正当な権利者に帰属し,ユーザーは,これらを無断で複製,転載,改変,その他の二次利用をすることはできません。
14. 本条の規定に違反して紛争が発生した場合、利用者は自己の費用と責任において当該紛争を解決するとともに、当社及び第三者に損害を与えないものとします。


### 第6条（禁止事項）
ユーザーは、本サービスの利用にあたり、以下の行為をしてはならないものとします。

15. 法令または公序良俗に違反する行為
16. 犯罪行為に関連する行為
15. 本サービスに含まれる著作権、商標権その他の知的財産権を侵害する行為
15. 当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
15. 本サービスによって得られた情報を商業的に利用する行為
15. 当社のサービスの運営を妨害するおそれのある行為
15. 不正アクセスをし、またはこれを試みる行為
15. 他のユーザーに関する個人情報等を収集または蓄積する行為
15. 他のユーザーに成りすます行為
15. 当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
15. その他、当社が不適切と判断する行為

### 第7条（本サービスの提供の停止等）
26. 当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。 
    1. 本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
    2. 地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
    3. コンピュータまたは通信回線等が事故により停止した場合
    4. その他、当社が本サービスの提供が困難と判断した場合
27. 当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。
### 第8条（利用制限および登録抹消）
28. 当社は、以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。 
    1. 本規約のいずれかの条項に違反した場合
    2. 登録事項に虚偽の事実があることが判明した場合
    3. 決済手段として当該ユーザーが届け出たクレジットカードが利用停止となった場合
    4. 料金等の支払債務の不履行があった場合
    5. 当社からの連絡に対し、一定期間返答がない場合
    6. 本サービスについて、最終の利用から一定期間利用がない場合
    7. その他、当社が本サービスの利用を適当でないと判断した場合
29. 当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
### 第9条（退会）
ユーザーは、所定の退会手続により、本サービスから退会できるものとします。
### 第10条（保証の否認および免責事項）
30. 当社は,本サービスに事実上または法律上の瑕疵（安全性,信頼性,正確性,完全性,有効性,特定の目的への適合性,セキュリティなどに関する欠陥,エラーやバグ,権利侵害などを含みます。）がないことを保証するものではありません。
31. 当社は,本サービスによってユーザーに生じたあらゆる損害について,一切の責任を負いません。ただし,本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合,この免責規定は適用されませんが,この場合であっても,当社は,当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し,または予見し得た場合を含みます。）について一切の責任を負いません。
32. 当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
### 第11条（サービス内容の変更等）
当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
### 第12条（利用規約の変更）
当社は、必要と判断した場合には、当社は、事前に利用者の承諾を得ることなく、本規約並びに本システムを変更・更新する場合がございます。なお、本規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約に同意したものとみなします。


### 第13条（個人情報の取扱い）
33. 当社は、本システムを通じ知り得た利用者の個人情報について、以下の目的で利用します。
\t1. 本システムの利用者の会員登録・管理、お店の予約・位置情報に基づいた近隣店舗の案内など本サービスの提供のため
\t2. 本システムの利用者へのイベント・キャンペーンのご案内・開催や外部サービスとの連携などによるユーザー満足度の向上・本サービスの利用促進のため
\t3. 本システムの利用者へのアンケート調査実施、ご利用状況等の解析などを行い、本サービスの改善・バージョンアップやユーザー満足度の向上・本サービスの利用促進のため
\t4. 当社及び当社グループ会社における新サービスの企画・立案及び開発、当社サービスの利用情報等を元にしたデータ解析、資料の作成及び提供のため
\t5. 本システムから利用者への案内・連絡や利用状況に基づいたレコメンド情報・メールマガジンの配信のため
34. 当社は、利用者が予約申込した本飲食店等へ提供する場合、又は以下のいずれかに該当する場合を除き、利用者の個人情報を第三者へ開示又は提供いたしません。
\t1. 利用者本人の同意がある場合
\t2. 第32項の利用目的のために必要のある場合
\t3. 犯罪捜査の為など警察、検察、裁判所、弁護士会またはこれらに準じた権限を有する機関から開示請求があった場合
\t4. 人の生命、身体又は財産の保護のために緊急に必要がある場合
35. 前各項に定めるほか、個人情報の取扱いについては個人情報の取扱いについては、当社が別途定めるプライバシーポリシーに記載の通りとし、利用者は、当社が当該プライバシーポリシーに従って個人情報を利用することに同意するものとします。
36. 当社は、利用者が予約申込した本飲食店等に対し、利用者が予約の際に入力した情報（個人情報を含みますが、これに限られません。）を提供することができるものとします。利用者は、当該本飲食店等における個人情報の利用は、当該本飲食店等が定めるプライバシーポリシー等に従って行なわれるものであり、当社のプライバシーポリシーに従って行なわれるものではないことを予め了承するものとします。
### 第14条（通知または連絡）
ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。
### 第15条（権利義務の譲渡の禁止）
ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
### 第16条（準拠法・裁判管轄）
37. 本規約の解釈にあたっては、日本法を準拠法とします。なお、本サービスに関しては、国際物品売買契約に関する国際連合条約の適用を排除するものとします。
38. 本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。


### 第17条（飲食店等の契約店舗との関係）
39. 本システムに登録された飲食店等の契約店舗（以下「本飲食店等」といいます。）との取引及び交渉は全て利用者と本飲食店等の間で行われるものとし、当社は、予約、予約のキャンセル、代金の支払いを含め、飲食店等と利用者間の関係について一切の責任を負わないものとします。
40. 利用者は、本システムを通じて本飲食店等への正式予約を入れた後に予約のキャンセル又は変更を行う場合は、本システムを通じて、または直接当該本飲食店等に連絡して、キャンセル又は変更手続を行うものとします。この場合、当該本飲食店等の定める料金規定に従って発生するキャンセル料金又は変更料金等は、直接利用者が当該本飲食店等に対して支払うものとします。本システムからキャンセル又は変更の手続ができる期限は、本飲食店等により異なり、期限日以降は、直接本飲食店等へキャンセル又は変更の通知をするものとします。その他、キャンセル料又は変更料金に関する取り決めは、当該本飲食店等のキャンセルポリシーによるものとし、当社は一切関与しません。
41. 利用者は本システムの利用にあたり、以下の点に注意するものとします。
\t1. 本システムにおける本飲食店等の空席状況・料金等は、当該本飲食店等が他で提供する情報と異なる場合がございます。
\t2. 本システムにおいて、本飲食店等が料金等を変更することがございます。この場合、変更後の料金は、変更後に予約された方のみに適用され、既に予約されている方はご予約時の料金が適用されます。
\t3. 本システムの本飲食店等に関する情報の提供は、本システムに登録する本飲食店等の責任において当該本飲食店等が直接行うものであり、提供された情報について当社は一切その責任を負いません。
\t4. 当社が利用者による連絡先の登録に不正があると判断した場合には、本飲食店等からの依頼に基づき、その予約を無効とすることができるものとします。


### 第18条（紛争処理及び損害賠償）
42. 利用者は、本規約に違反することにより、又は本システムの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
43. 利用者が、本システムに関連して他の利用者、本飲食店等その他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、利用者の費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
44. 利用者による本システムの利用に関連して、当社が、他の利用者、本飲食店等その他の第三者から権利侵害その他の理由により何らかの請求を受けた場合は、利用者は当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。
45. 当社は、本システムに関連して利用者が被った損害について、一切賠償の責任を負いません。なお、消費者契約法の適用その他の理由により、本項その他当社の損害賠償責任を免責する規定にかかわらず当社が利用者に対して損害賠償責任を負う場合においても、当社の賠償責任は、1000円を上限とします。


### 第19条（本システム利用の際の環境設定）
本システムは、一般的な文字表示（日本語表示）やメール等の諸設定を適切に設定してご利用下さい。設定が一般的でない場合には、動作結果やそれがもたらす諸影響に関し、当社は一切責任を負わないものとします。また、OSやLAN環境、ご利用機器の事情によって本システムが正しく作動しない場合も同様とします。


#### 以上  
2022年12月11日制定
`

const termsStyle = {
    h1: ({node, ...props}) => <div className={"text-2xl not-italic font-bold text-center my-5"} {...props}/>,
    h3: ({node, ...props}) => <div className={"text-lg not-italic font-bold text-left mt-3 mb-1"} {...props}/>,
    ol: ({node, ...props}) => <ol {...props} className={"ml-8 list-decimal list-inside"}/>,
    ul: ({node, ...props}) => <ol {...props} className={"ml-8 list-decimal list-inside"}/>,
    li: ({node, ...props}) => <li className={""} {...props}></li>,
    h4: ({node, ...props}) => <div className={"text-right mt-4"} {...props}></div>,
}

export const Terms = ({setTitle}) => {
    const first = useRef(false);
    useEffect(() => {
        if (setTitle === undefined) return;
        if (first.current) return;
        first.current = false;
        console.log("DDD")
        setTitle("admin")
    }, [setTitle]);
    return (
        <div className={"p-8"}>
            <ReactMarkdown className={"text-left font-sans not-italic"} components={termsStyle}>{terms}</ReactMarkdown>
        </div>
    )
}