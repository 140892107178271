import React, {useEffect, useRef, useState} from "react";
import apiClient from "../components/apiClient";
import Header from "../components/Header";
import {Link, useNavigate} from "react-router-dom";
import Popup from 'reactjs-popup';
// or for Day.js
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
// or for Luxon
// or for Moment.js
import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import {TimePicker} from "@mui/x-date-pickers";
import {LoaderCircle} from "../components/loader";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';

import 'react-tabs/style/react-tabs.css';

const DeletePopup = ({el, yes}) => {
    return (
        <Popup trigger={el} modal nested>
            {close =>
                (
                    <div className={"modal my-4"}>
                        <div className={"text-center font-bold"}>本当に削除しますか？</div>
                        <div className={"flex items-center justify-center py-4"}>
                            <button className={"text-white bg-gray-600 px-3 py-1 rounded mr-4"} onClick={close}>いいえ
                            </button>
                            <button className={"text-white bg-red-600 px-3 py-1 rounded"} onClick={() => {
                                yes();
                                close();
                            }}>はい
                            </button>
                        </div>
                    </div>
                )
            }
        </Popup>
    )
}

const ReserveSection = ({cart, timeValue, setTimeValue, isAvailable, TakeOutSection, EatInSection, selectedSeat}) => {
    if (cart.shop.activeMenuID === "NONE") {
        return (
            <div className={"flex justify-center items-center bg-yellow-400 rounded-lg drop-shadow-lg py-4 m-4"}>
                <div className={"font-bold"}>
                    現在、持ち帰りを受け付けていません。
                </div>
            </div>
        )
    }
    // setValue(cart.shop.AvailableTime[0].Label.split(" ")[1])
    isAvailable.current = true;
    return (
        <Tabs className={"mt-4"}>
            <TabList>
                <Tab>持ち帰り</Tab>
                {process.env.REACT_APP_ENABLE_EAT_IN === "true" ?
                    <Tab>店内注文</Tab> : null}
            </TabList>

            <TabPanel>
                <div
                    className={"flex justify-center items-center bg-yellow-400 rounded-lg drop-shadow-lg py-4 m-4 mt-0 flex flex-col"}>
                    <div className={"text-xl font-bold my-2 border-b border-black"}>持ち帰り予約注文をします</div>
                    <div className={"mb-4 font-bold"}>受け取る時間を選択</div>
                    <div className={"flex items-center"}>
                        <div
                            className={"mr-4"}>{dayjs(cart.shop.availableDuration.start.timeID).format("MM月DD日")}</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                ampm={true}
                                label="受け取り時刻"
                                value={dayjs(timeValue)}
                                minTime={dayjs(cart.shop.availableDuration.start.timeID + 1000 * 60 * 4)}
                                maxTime={dayjs(cart.shop.availableDuration.end.timeID - 1000 * 60 * 5)}
                                onChange={(nv) => {
                                    console.log(nv)
                                    const now = new Date();
                                    const floorSec = new Date(now.getFullYear(), now.getMonth(), now.getDate(), nv.hour(), nv.minute())
                                    setTimeValue(floorSec)
                                }}
                                renderInput={(params) => <TextField {...params}/>}
                                shouldDisableTime={(tv, cy) => {
                                    return cy === "minutes" && tv % 5;

                                }}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                {TakeOutSection}
            </TabPanel>
            {process.env.REACT_APP_ENABLE_EAT_IN === "true" ?
                <TabPanel>
                    <div
                        className={"flex justify-center items-center bg-yellow-400 rounded-lg drop-shadow-lg py-4 m-4 mt-0 flex flex-col"}>
                        <div className={"text-xl font-bold my-2 border-b border-black"}>店舗内の席から注文します</div>
                        <div className={"mb-4 font-bold"}>現在の席を選択</div>
                        <div className={"flex items-center"}>
                            <select className={"p-3 rounded drop-shadow"} onChange={(e) => {
                                selectedSeat.current = e.target.value;
                            }}>
                                {cart.shop.seats.map(seat => {
                                    const selected = selectedSeat.current === "";
                                    if (selected) {
                                        selectedSeat.current = seat;
                                        return (
                                            <option value={seat} defaultChecked={selected}>{seat}</option>
                                        )
                                    } else {
                                        return (
                                            <option value={seat}>{seat}</option>
                                        )
                                    }
                                })}
                            </select>
                        </div>
                    </div>
                    {EatInSection}
                </TabPanel>
                : null}
        </Tabs>
    )
}

const Cart = () => {
    const [cart, setCart] = useState({items: []});
    const [totalPrice, setTotalPrice] = useState(0);
    const [agree, setAgree] = useState(false);
    const isAvailable = useRef(false);
    const [timeValue, setTimeValue] = useState(new Date())
    const [loader, setLoader] = useState(false);
    const selectedSeat = useRef("");
    const nav = useNavigate();
    const first = useRef(false);
    useEffect(() => {
        if (first.current) return;
        first.current = true;
        (async () => {
            const cartData = await apiClient.auth.cartGet(true);
            console.log("cartData", cartData)
            const data = await cartData.json();
            setCart(data);
            console.log(data)
            // if (cartData.shop.AvailableTime.length !== 0) {
            //     setTimeValue(dayjs("2018-01-01T" + cartData.shop.AvailableTime[0].Label.split(" ")[1]))
            // }
            // setTimeValue(dayjs(data.shop.AvailableDuration.start.TimeID))
            setTimeValue(new Date(data.shop.availableDuration.start.timeID + 1000 * 60 * 5));
        })();
    }, [])

    useEffect(() => {
        let total = 0;
        for (let item of cart.items) {
            let current = item.price;

            for (let opt of item.options) {
                console.log(opt)
                current += Number(opt.price);
            }
            total += current * item.num;
        }
        setTotalPrice(total)
    }, [cart])

    const changeItem = (num, item) => {
        (async () => {
            let options = {}
            for (let opt of item.options) {
                options[opt.optionID] = opt.choiceID
            }
            const res = await apiClient.auth.cartPut(cart.shopID, item.itemID, options, num)
            setCart(await res.json())
        })()
    }

    const TakeOutSection = () => {
        return (
            <div
                className={"flex flex-col justify-center items-center text-xl text-red-600 font-bold bg-white rounded-lg drop-shadow-lg py-4 m-4"}>
                <div className={"flex"}>
                    <div className={"mr-2"}>合計金額</div>
                    <div>¥{totalPrice.toLocaleString()}</div>
                </div>
                <div className={"font-normal text-base my-2 text-black flex items-center justify-center"}>
                    <input type={"checkbox"} onClick={(e) => {
                        setAgree(e.target.checked && isAvailable.current)
                        console.log(e)
                    }} defaultChecked={agree}/>
                    {/*<div className={"ml-2"}><a href={"/利用規約.html"}*/}
                    {/*                           className={"text-blue-600"}>利用規約</a>に同意*/}
                    {/*</div>*/}
                    <div className={"ml-2"}><Link to={"/terms_of_contact"}
                                                  className={"text-blue-600"}>利用規約</Link>に同意
                    </div>
                </div>
                <button
                    className={"text-white px-6 py-2 rounded-xl my-2 text-lg " + (agree ? "bg-red-600" : "bg-gray-600")}
                    disabled={!agree}
                    onClick={() => {
                        (async () => {
                            console.log(timeValue.getTime());
                            setLoader(true);
                            const res = await apiClient.auth.orderPost(timeValue.getTime());
                            const data = await res.json();
                            console.log(data)
                            await new Promise(s => setTimeout(s, 1500))
                            setLoader(false)
                            if (res.status === 200) {
                                nav("/order")
                            } else {
                                window.alert(data.message)
                            }
                        })()
                    }}>利用規約に同意して持ち帰り予約注文
                </button>
            </div>
        )
    }

    const EatInSection = () => {
        return (
            <div
                className={"flex flex-col justify-center items-center text-xl text-red-600 font-bold bg-white rounded-lg drop-shadow-lg py-4 m-4"}>
                <div className={"flex"}>
                    <div className={"mr-2"}>合計金額</div>
                    <div>¥{totalPrice.toLocaleString()}</div>
                </div>
                <div className={"font-normal text-base my-2 text-black flex items-center justify-center"}>
                    <input type={"checkbox"} onClick={(e) => {
                        setAgree(e.target.checked)
                        console.log(e)
                    }} defaultChecked={agree}/>
                    {/*<div className={"ml-2"}><a href={"/利用規約.html"}*/}
                    {/*                           className={"text-blue-600"}>利用規約</a>に同意*/}
                    {/*</div>*/}
                    <div className={"ml-2"}><Link to={"/terms_of_contact"}
                                                  className={"text-blue-600"}>利用規約</Link>に同意
                    </div>
                </div>
                <button
                    className={"text-white px-6 py-2 rounded-xl my-2 text-lg " + (agree ? "bg-red-600" : "bg-gray-600")}
                    disabled={!agree}
                    onClick={() => {
                        (async () => {
                            console.log(timeValue)
                            console.log(timeValue.getTime())
                            setLoader(true)
                            const res = await apiClient.auth.orderPostEatIn(timeValue.getTime(), selectedSeat.current);
                            const data = await res.json()
                            console.log(data)
                            await new Promise(s => setTimeout(s, 1500))
                            setLoader(false)
                            if (res.status === 200) {
                                nav("/order")
                            } else {
                                window.alert(data.message)
                            }
                        })()
                    }}>利用規約に同意して店内から注文
                </button>
            </div>
        )
    }

    return (
        <div className={"pb-4"}>
            <LoaderCircle loader={loader} label={"注文中..."}/>
            <Header title={"注文"}/>
            <div className={"max-w-6xl mx-auto pt-28"}>
                {cart.items.length === 0 ? <div>
                        <div className={"font-bold"}>カートに商品を追加してください</div>
                        <Link to={"/shop"}><img src={"/img/logo.png"} alt={"WannaBuy"} className={"p-4 mx-auto"}/></Link>
                    </div> :
                    <div>

                        {cart.shopName === undefined ? null : (
                            <div>
                                <div className={"text-xl font-bold"}>{cart.shopName}</div>
                                <div className={"flex flex-wrap justify-center"}>
                                    {cart.items.map(item => {
                                        let calcPrice = item.price;
                                        for (let opt of item.options) {
                                            calcPrice += Number(opt.price);
                                        }
                                        return (
                                            <div className={"m-4 p-4 my-2 text-left bg-white rounded-xl lg:basis-5/12"}
                                                 key={JSON.stringify(item)}>
                                                <div className={"flex flex-row justify-between"}>
                                                    <div className={"font-bold"}>{item.name}</div>
                                                    <DeletePopup el={<button
                                                        className={"px-3 py-1 bg-red-600 text-white rounded text-sm"}>削除</button>}
                                                                 yes={() => {
                                                                     (async () => {
                                                                         let options = {}
                                                                         for (let opt of item.options) {
                                                                             options[opt.optionID] = opt.choiceID
                                                                         }
                                                                         const res = await apiClient.auth.cartDelete(cart.shopID, item.itemID, options)
                                                                         setCart(await res.json())
                                                                     })()
                                                                 }}/>
                                                    {/*<button*/}
                                                    {/*    className={"px-3 py-1 bg-red-600 text-white rounded text-sm"}*/}
                                                    {/*    onClick={() => {*/}
                                                    {/*        // (async () => {*/}
                                                    {/*        //     const res = await apiClient.auth.cartDelete(cart.shopID, item.itemID, item.options)*/}
                                                    {/*        //     setCart(res)*/}
                                                    {/*        // })()*/}
                                                    {/*    }}>削除*/}
                                                    {/*</button>*/}
                                                </div>
                                                <div className={"flex flex-row-reverse"}>
                                                    <div className={"basis-2/5 pt-2"}>
                                                        <img src={item.img} alt={item.name} className={"w-full"}/>
                                                    </div>
                                                    <div className={"w-full text-sm pl-2"}>
                                                        <div>☆オプション</div>
                                                        <div>
                                                            {item.options.map(opt => {
                                                                return (
                                                                    <div className={"flex ml-1"} key={opt.optionID}>
                                                                        ・
                                                                        <div>{opt.optionLabel}</div>
                                                                        <div className={"mx-1"}>-</div>
                                                                        <div>{opt.choiceLabel}</div>
                                                                        <div>(¥{opt.price})</div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"flex"}>
                                                    <div>￥{calcPrice}</div>
                                                    <div className={"mx-2"}>✕</div>
                                                    <button className={""} onClick={() => {
                                                        changeItem(-1, item)
                                                    }}><img src={"/img/minus.svg"} className={"w-5"} alt={"減らす"}/>
                                                    </button>
                                                    <div className={"mx-1"}>{item.num}</div>
                                                    <button className={""} onClick={() => {
                                                        changeItem(1, item)
                                                    }}><img src={"/img/plus.svg"} className={"w-5"} alt={"増やす"}/>
                                                    </button>
                                                    <div className={"mx-2"}>=</div>
                                                    <div>￥{(calcPrice * item.num).toLocaleString()}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                        <ReserveSection cart={cart} timeValue={timeValue} TakeOutSection={<TakeOutSection/>}
                                        EatInSection={<EatInSection/>} setTimeValue={setTimeValue}
                                        selectedSeat={selectedSeat}
                                        isAvailable={isAvailable}/>

                    </div>
                }
            </div>
        </div>
    )
}

export default Cart