import {CirclesWithBar} from "react-loader-spinner";
import React from "react";

export const LoaderCircle = ({loader, label}) => {
    if (!loader) return null;
    return (
        <div
            className={"z-40 w-full bg-black opacity-75 flex items-center justify-center h-full flex-col left-0 top-0 fixed"}>
            <CirclesWithBar
                height="200"
                width="200"
                color="#4fa94d"
                wrapperStyle={{}}
                visible={loader}
                ariaLabel='circles-with-bar-loading'
            />
            <div className={"text-3xl z-50 text-white my-3"}>{label}</div>
        </div>
    )
}